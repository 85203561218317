import { useMemo } from 'react';

import { SystemRoleName } from '@jebel/constants';

import { useAppAuth } from 'providers';
import { SystemRoleFragment, useCurrentUserRolesQuery } from 'shared/graphql';

/**
 * Custom hook to fetch and return the current user's roles.
 *
 * This hook uses the `useAuth` hook to check if the user is authorized, and then fetches the user's roles using the `useCurrentUserRolesQuery` hook.
 */
export function useCurrentUserRoles() {
  const { hasSession } = useAppAuth();

  const { data: response, loading: rolesLoading } = useCurrentUserRolesQuery({
    skip: !hasSession,
  });

  const roles: SystemRoleFragment[] = useMemo(() => response?.roles?.items ?? [], [response]);

  const isPlatformAdmin = useMemo(() => {
    return roles.some(role => role.name === SystemRoleName.PlatformAdmin);
  }, [roles]);

  return {
    data: roles,
    isPlatformAdmin,
    loading: rolesLoading,
  };
}
