/** @deprecated Use `isInteger` instead. */
export { isInteger as isInteger } from './types';
/** Allow to convert readable numbers to [Stripe amount format](https://stripe.com/docs/api/charges/object#charge_object-amount). */
export const TO_STRIPE_CENTS = 100;
/** Allow to convert the [Stripe amount format](https://stripe.com/docs/api/charges/object#charge_object-amount) to readable numbers. */
export const FROM_STRIPE_CENTS = 0.01;
/**
 * Formats a given number as a currency string in USD.
 *
 * @param amount - The amount of money to format.
 * @param digits - The number of decimal places to include in the formatted string. Defaults to 2.
 * @returns The formatted currency string.
 */
export function formatMoney(amount, digits = 2) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: digits,
    });
    return formatter.format(amount);
}
/**
 * Formats a monetary value from cents to a string representation.
 *
 * @param value - The monetary value in cents. Can be a number, null, or undefined.
 * @param minimumFractionDigits - The minimum number of fraction digits to display. Defaults to 0.
 * @returns The formatted monetary value as a string.
 */
export const formatMoneyFromCents = (value, minimumFractionDigits = 0) => {
    return formatMoney(fromStripeCents(value), minimumFractionDigits);
};
/**
 * Converts an amount in Stripe cents to the corresponding amount in the base currency.
 *
 * @param cents - The amount in Stripe cents.
 * @returns The equivalent amount in the base currency.
 */
export function fromStripeCents(cents) {
    return (cents !== null && cents !== void 0 ? cents : 0) * FROM_STRIPE_CENTS;
}
/**
 * Converts a given amount to Stripe cents.
 *
 * @param amount - The amount to be converted. It can be a number, null, or undefined.
 * @returns The amount converted to Stripe cents.
 */
export function toStripeCents(amount) {
    return (amount !== null && amount !== void 0 ? amount : 0) * TO_STRIPE_CENTS;
}
