import { css, Theme } from '@emotion/react';
import { Box, Divider, ListItemText, MenuList } from '@material-ui/core';
import { Fragment, ReactNode, useCallback, useMemo } from 'react';

import { useAppAuth } from 'providers/useAppAuth';
import { NavListItemSymbol, ListItemIconSymbol } from 'shared/components/symbols';
import { Popover } from 'shared/components/ui';
import { APP_URL } from 'shared/constants';
import {
  useCurrentUser,
  useCurrentUserOrganizations,
  useCurrentUserSchools,
  useFeatureEnabled,
  useResponsive,
  useSchoolConfiguration,
} from 'shared/hooks';

import {
  HeaderNavigationUserProfile,
  HeaderNavigationUserProfileProps,
} from './HeaderNavigationUserProfile';

export interface HeaderNavigationPopoverProps extends HeaderNavigationUserProfileProps {
  isAdminRoute?: boolean;
  target: ReactNode;
}

const ADVERTISING_DOMAIN = process.env.REACT_APP_ADVERTISING_DOMAIN;
const CONTENT_CONTAINER_SIZE = 250;

const userProfileContainerCss = css`
  padding: 15px 25px;
  overflow: hidden;
  width: 100%;
`;

const commonNavItemCss = css`
  font-size: 16px;
  line-height: 26px;
  padding-left: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const navItemIconCss = css`
  width: 20px;
  height: 20px;
  margin-right: 1rem;
`;

const accountNavItemCss = (theme: Theme) => css`
  ${commonNavItemCss};
  color: ${theme.palette.text.secondary};
`;

const signOutNavItemCss = (theme: Theme) => css`
  ${commonNavItemCss};
  color: ${theme.palette.secondary.light};
`;

export function HeaderNavigationPopover({
  isAdminRoute,
  userLoading,
  target,
}: HeaderNavigationPopoverProps) {
  const { user, isAdmin: hasAdminRole } = useCurrentUser();
  const { isMobile } = useResponsive();
  const { data: organizations } = useCurrentUserOrganizations();
  const { enabled: showSchoolSwitch } = useFeatureEnabled('showSchoolSwitch');
  const { selectSchool } = useSchoolConfiguration();
  const { logout } = useAppAuth();

  const { data: switchableSchools } = useCurrentUserSchools({
    skipCurrentSchool: true,
  });

  const switchToAdvertising = useCallback(() => {
    window.location.assign(`${ADVERTISING_DOMAIN}/dashboard?fromCommunity`);
  }, []);

  const showSwitchCommunityAdministration = useMemo(() => {
    return hasAdminRole;
  }, [hasAdminRole]);

  const showSwitchAdvertising = useMemo(() => {
    return organizations.length > 0 && !isMobile;
  }, [organizations, isMobile]);

  const handleLogout = () => {
    logout();
  };

  const createHandleSwitchSchool = useCallback(
    (id: string) => () => {
      selectSchool(id);
      // Refresh the page to avoid inconsistent state and permissions.
      window.open('/', '_self');
    },
    [selectSchool],
  );

  return (
    <Popover
      target={target}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      elevation={4}
    >
      {popover => (
        <MenuList style={{ width: CONTENT_CONTAINER_SIZE }} disablePadding>
          <Box css={userProfileContainerCss}>
            <HeaderNavigationUserProfile userLoading={userLoading} user={user} isPopover />
          </Box>

          <Divider />

          {showSchoolSwitch && switchableSchools.length > 0 && (
            <Fragment>
              {switchableSchools.map(school => (
                <NavListItemSymbol
                  onClosePopover={popover.close}
                  key={school.id as string}
                  button
                  listItemText={<ListItemText>{school.name}</ListItemText>}
                  startIcon={<ListItemIconSymbol name="School" css={navItemIconCss} />}
                  dense
                  disableGutters
                  disableRipple={false}
                  css={accountNavItemCss}
                  onClick={createHandleSwitchSchool(school.id as string)}
                />
              ))}

              <Divider />
            </Fragment>
          )}

          <NavListItemSymbol
            onClosePopover={popover.close}
            button
            listItemText={<ListItemText>My Account</ListItemText>}
            startIcon={
              <ListItemIconSymbol
                name="AccountCircle"
                variant="filled"
                color="inherit"
                fontSize="small"
                viewBox="0 0 24 24"
                css={navItemIconCss}
              />
            }
            dense
            disableGutters
            path={APP_URL.user.settings.information}
            disableRipple={false}
            css={accountNavItemCss}
          />

          {showSwitchCommunityAdministration && (
            <NavListItemSymbol
              onClosePopover={popover.close}
              button
              listItemText={
                <ListItemText>Switch to {isAdminRoute ? 'Member' : 'Admin'}</ListItemText>
              }
              startIcon={<ListItemIconSymbol name="Settings" css={navItemIconCss} />}
              dense
              disableGutters
              path={isAdminRoute ? APP_URL.user.home.index : APP_URL.admin.dashboard.index}
              disableRipple={false}
              css={accountNavItemCss}
            />
          )}

          {showSwitchAdvertising && (
            <NavListItemSymbol
              onClick={switchToAdvertising}
              button
              listItemText={<ListItemText>Switch to Advertising</ListItemText>}
              startIcon={<ListItemIconSymbol name="Campaign" css={navItemIconCss} />}
              dense
              disableGutters
              disableRipple={false}
              css={accountNavItemCss}
            />
          )}

          <Divider />

          <NavListItemSymbol
            button
            listItemText={<ListItemText onClick={handleLogout}>Sign Out</ListItemText>}
            startIcon={
              <ListItemIconSymbol
                name="PowerSettingsNew"
                variant="filled"
                color="inherit"
                fontSize="small"
                viewBox="0 0 24 24"
                css={navItemIconCss}
              />
            }
            dense
            disableGutters
            path={APP_URL.public.login}
            css={signOutNavItemCss}
          />
        </MenuList>
      )}
    </Popover>
  );
}
