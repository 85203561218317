import { useMemo } from 'react';

import { useAppAuth } from 'providers/useAppAuth';
import { useCurrentUserQuery } from 'shared/graphql';
import { recordDebug, recordError } from 'shared/utils/record';

import { useCurrentUserPermissions } from './useCurrentUserPermissions';

export function useCurrentUser() {
  const { isSchoolAdmin, loading: permissionsLoading } = useCurrentUserPermissions();
  const { hasSession, logout } = useAppAuth();

  const {
    data: response,
    loading: userLoading,
    error,
  } = useCurrentUserQuery({
    skip: !hasSession,
    // Poll every minute to keep the session alive.
    pollInterval: 1000 * 60 * 1,

    onError(error) {
      recordDebug('Closing session due no user was found with the current token.');
      recordError(error);
      // Close the current session because not user can be setup.
      logout();
    },
  });

  const user = response?.user;

  const isBusinessAdminOrManager = useMemo(() => {
    const ownedOrganizations = user?.ownedOrganizations?.items ?? [];
    const managedOrganizations = user?.managerPreferences?.items ?? [];

    return ownedOrganizations.length > 0 || managedOrganizations.length > 0;
  }, [user]);

  return {
    user,
    /** @deprecated Use {@link user} instead. */
    userId: user?.id,
    loading: userLoading || permissionsLoading,
    error,
    /** @deprecated Use from `useCurrentUserPermissions`. */
    isAdmin: isSchoolAdmin,
    isBusinessAdminOrManager,
  };
}
