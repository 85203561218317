import { CircularProgress } from '@material-ui/core';
import { useEffect, useLayoutEffect, useMemo } from 'react';
import { Route, Redirect, useLocation, RouteProps } from 'react-router-dom';

import { UserStatus } from '@jebel/constants';

import { useAppAuth } from 'providers/useAppAuth';
import { CenterLayout } from 'shared/components/layouts';
import { matchRoutesPaths } from 'shared/components/ui/common';
import { ROUTES } from 'shared/constants';
import {
  getAccessibleLayouts,
  useCurrentUser,
  useCurrentUserRoleAddons,
  usePushNotifications,
  useToast,
  useRoleAddons,
  useCurrentUserActiveCommunitiesCount,
  useCurrentUserRoles,
} from 'shared/hooks';

import { renderContent } from './routerUtils';

export type ProtectedRouteProps = RouteProps & {
  redirectTo?: string;
};

export function ProtectedRoute({
  component,
  children,
  render,
  redirectTo = ROUTES.public.login,
  ...props
}: ProtectedRouteProps) {
  const { requestPermission } = usePushNotifications();
  const { showWarning } = useToast();
  const { hasSession, logout } = useAppAuth();
  const { user, loading: userLoading } = useCurrentUser();
  const { pathname } = useLocation();
  const { isPlatformAdmin, loading: userSystemRolesLoading } = useCurrentUserRoles();
  const { data: userRoles, loading: userRolesLoading } = useCurrentUserRoleAddons();
  const { rolesAddons, isLoading: rolesLoading } = useRoleAddons();

  const { count: communitiesCount, loading: communitiesCountLoading } =
    useCurrentUserActiveCommunitiesCount();

  const loading = userLoading || userRolesLoading || rolesLoading || userSystemRolesLoading;

  useEffect(() => {
    if (hasSession && !userLoading && user?.status === UserStatus.Inactive) {
      showWarning('Your account is not active, please contact with administration');
      requestCloseSession();
    }
  }, [hasSession, userLoading, user]);

  useEffect(() => {
    if (hasSession && !communitiesCountLoading && communitiesCount === 0) {
      // Users without any active school shouldn't be able to log-in.
      // https://github.com/jebelapp/jebel/issues/1727
      showWarning('You do not have any school, please contact with administration');
      requestCloseSession();
    }
  }, [hasSession, communitiesCountLoading, communitiesCount]);

  useLayoutEffect(() => {
    if (user?.id) {
      // https://github.com/jebelapp/jebel/issues/1517
      requestPermission();
    }
  }, [user]);

  const requestCloseSession = async () => {
    await logout();
  };

  const isRouteAllowed = useMemo(() => {
    if (!hasSession) {
      return false;
    }

    if (isPlatformAdmin) {
      return true;
    }

    const routes = getAccessibleLayouts(userRoles, rolesAddons).map(item => ({
      path: item.route as string,
    }));

    return matchRoutesPaths(pathname, routes);
  }, [pathname, rolesAddons, userRoles, user]);

  if (!hasSession) {
    return <Redirect to={redirectTo} />;
  }

  if (loading) {
    return (
      <CenterLayout>
        <CircularProgress />
      </CenterLayout>
    );
  }

  if (!isRouteAllowed) {
    return <Redirect to={ROUTES.user.home.index} />;
  }

  return (
    <Route {...props} render={props => renderContent({ component, render, children, props })} />
  );
}
