/**
 * Defining the routes for the community layout.
 */
export var CommunityLayoutRoutes;
(function (CommunityLayoutRoutes) {
    CommunityLayoutRoutes["Home"] = "/home";
    /**
     * @remark Also known as "School News"
     */
    CommunityLayoutRoutes["CommunityNews"] = "/news";
    CommunityLayoutRoutes["ExplorerNation"] = "/explorer";
    CommunityLayoutRoutes["Organizations"] = "/organizations";
    /**
     * @remark Also known as "Collective Intelligence"
     */
    CommunityLayoutRoutes["ExplorerForum"] = "/collective";
    CommunityLayoutRoutes["Discounts"] = "/discounts";
    /**
     * @remark Also known as "Jobs"
     */
    CommunityLayoutRoutes["CareerServices"] = "/services";
    CommunityLayoutRoutes["Events"] = "/events";
    CommunityLayoutRoutes["Groups"] = "/groups";
    CommunityLayoutRoutes["Legacy"] = "/legacy";
    CommunityLayoutRoutes["ClassScorecard"] = "/scorecard";
    CommunityLayoutRoutes["Inbox"] = "/mail";
})(CommunityLayoutRoutes || (CommunityLayoutRoutes = {}));
/**
 * A mapping of `CommunityLayoutRoutes` enum values to their corresponding route names.
 * This object is used to define the route names for various community layout routes.
 */
export const COMMUNITY_LAYOUT_ROUTE_NAMES = {
    [CommunityLayoutRoutes.Home]: 'Home',
    [CommunityLayoutRoutes.CommunityNews]: 'Community Posts',
    [CommunityLayoutRoutes.ExplorerNation]: 'Members',
    [CommunityLayoutRoutes.Organizations]: 'Organizations',
    [CommunityLayoutRoutes.ExplorerForum]: 'Forum',
    [CommunityLayoutRoutes.Discounts]: 'Discounts',
    [CommunityLayoutRoutes.CareerServices]: 'Career Services',
    [CommunityLayoutRoutes.Events]: 'Events',
    [CommunityLayoutRoutes.Groups]: 'Groups',
    [CommunityLayoutRoutes.Legacy]: 'Legacy',
    [CommunityLayoutRoutes.ClassScorecard]: 'Class Scorecard',
    [CommunityLayoutRoutes.Inbox]: 'Inbox',
};
/**
 * A map of `CommunityLayoutRoutes` to their corresponding Material UI icon names.
 */
export const COMMUNITY_LAYOUT_ROUTE_ICONS = {
    [CommunityLayoutRoutes.Home]: 'Home',
    [CommunityLayoutRoutes.CommunityNews]: 'Newspaper',
    [CommunityLayoutRoutes.ExplorerNation]: 'PeopleAlt',
    [CommunityLayoutRoutes.Organizations]: 'Apartment',
    [CommunityLayoutRoutes.ExplorerForum]: 'QuestionAnswer',
    [CommunityLayoutRoutes.Discounts]: 'Discount',
    [CommunityLayoutRoutes.CareerServices]: 'Work',
    [CommunityLayoutRoutes.Events]: 'EventNote',
    [CommunityLayoutRoutes.Groups]: 'Groups',
    [CommunityLayoutRoutes.Legacy]: 'History',
    [CommunityLayoutRoutes.ClassScorecard]: 'Equalizer',
    [CommunityLayoutRoutes.Inbox]: 'Mail',
};
