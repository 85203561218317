import { FileValue } from '@8base-react/file-input';
import { Close, Collections } from '@material-ui/icons';
import { FileUpload } from '@mui/icons-material';
import { AvatarProps, Skeleton } from '@mui/material';
import { PickerOptions } from 'filestack-js';

import { ONE_MEGABYTE } from 'shared/constants/files';
import { useModalState } from 'shared/hooks';

import { FileInputWrap as FileInput } from '../FileInputWrap';
import { Backdrop, Container, Image } from './AvatarImageInput.styles';

const MAX_FILE_SIZE = ONE_MEGABYTE * 10;

export interface AvatarImageInputProps extends Omit<AvatarProps, 'onChange'> {
  onChange: (value: FileValue | null) => void;
  loading?: boolean;
}

export function AvatarImageInput({
  onChange,
  loading,
  children = <Collections />,
  ...props
}: AvatarImageInputProps) {
  const { show, open, close } = useModalState();

  const onPickImage = (pick: (options: PickerOptions) => Promise<unknown>) => {
    pick({
      accept: 'image/*',
      fromSources: ['local_file_system'],
      maxSize: MAX_FILE_SIZE,
    });
  };

  const handlePick = (resultFile: FileValue | FileValue[]) => {
    if (!resultFile || Array.isArray(resultFile)) {
      return;
    }

    onChange(resultFile);
  };

  const handleRemove = () => {
    onChange(null);
  };

  if (loading) {
    return (
      <Skeleton variant="circular">
        <Image {...props}>
          <FileUpload />
        </Image>
      </Skeleton>
    );
  }

  return (
    <FileInput maxFiles={1} onChange={handlePick}>
      {({ pick }) => (
        <Container onMouseOver={open} onMouseLeave={close}>
          {show ? (
            props.src ? (
              <Backdrop onClick={handleRemove}>
                <Close />
              </Backdrop>
            ) : (
              <Backdrop onClick={() => onPickImage(pick)}>
                <FileUpload />
              </Backdrop>
            )
          ) : null}

          <Image {...props}>{children}</Image>
        </Container>
      )}
    </FileInput>
  );
}
