import { createContext, PropsWithChildren, useEffect } from 'react';
import OneSignal from 'react-onesignal';

import { useToast } from 'shared/hooks';
import { PushNotificationProvider } from 'shared/types';
import { recordDebug, recordException } from 'shared/utils/record';

const APP_ID = process.env.REACT_APP_ONESIGNAL_APP_ID;
const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

if (APP_ID) {
  // Initialize OneSignal once.
  OneSignal.init({
    appId: APP_ID,
    allowLocalhostAsSecureOrigin: IS_DEVELOPMENT,
  });
}

// Replace when https://github.com/OneSignal/react-onesignal/pull/141 passes.
interface IncomingNotificationEvent {
  readonly notification: {
    title?: string;
  };
}

const OneSignalPushNotificationProvider: PushNotificationProvider = {
  async setCurrentUser(id) {
    recordDebug(`Setting current user to ${id} on OneSignal`);

    try {
      await OneSignal.login(id);
    } catch (err) {
      recordException(err);
    }
  },

  async resetCurrentUser() {
    recordDebug('Resetting current user on OneSignal');

    try {
      await OneSignal.logout();
    } catch (err) {
      recordException(err);
    }
  },

  async requestPermission() {
    const isAvailable = OneSignal.Notifications.isPushSupported();

    if (!isAvailable) {
      recordDebug('Push notifications are not supported');
      return;
    }

    const status = OneSignal.Notifications.permissionNative;

    if (status === 'denied' || status === 'granted') {
      recordDebug(`Push notifications permission has been ${status}`);
      return;
    }

    try {
      recordDebug('Requesting permission for push notifications');
      await OneSignal.Notifications.requestPermission();
    } catch (err) {
      recordException(err);
    }
  },
};

export const OneSignalContext = createContext<PushNotificationProvider>(
  OneSignalPushNotificationProvider,
);

/**
 * Setup **OneSignal**.
 * https://github.com/jebelapp/jebel/issues/1516
 */
export function OneSignalProvider(props: PropsWithChildren<unknown>) {
  const { showMessage } = useToast();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    recordDebug('Registering OneSignal event listener');

    const handleNotificationIncoming = (event: IncomingNotificationEvent) => {
      if (event.notification.title) {
        // Show the notification title on the screen.
        showMessage(`Notification: ${event.notification.title}`);
      }
    };

    OneSignal.Notifications.addEventListener('foregroundWillDisplay', handleNotificationIncoming);

    return () => {
      OneSignal.Notifications.removeEventListener(
        'foregroundWillDisplay',
        handleNotificationIncoming,
      );
    };
  }, []);

  return (
    <OneSignalContext.Provider value={OneSignalPushNotificationProvider}>
      {props.children}
    </OneSignalContext.Provider>
  );
}
