import { Fragment, useLayoutEffect, useMemo } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { adminRoutes, userRoutes } from 'routes';

import { OnboardingPagesRouter } from 'features/onboarding/pages/OnboardingPagesRouter';
import { useCustomTheme, useGlobalState } from 'providers';
import { useAppAuth } from 'providers/useAppAuth';
import { MarkPostModal, EditPostModal } from 'shared/components/dialogs';
import { PageLayout } from 'shared/components/layouts';
import {
  HeaderNavigation,
  SidebarNavigation,
  SidebarNavigationMobile,
  HeaderNavigationMobile,
  BottomNavigation,
  SIDEBAR_OPEN_WIDTH,
  SIDEBAR_CLOSED_WIDTH,
} from 'shared/components/navigation';
import { DashboardPageSkeleton, FeedPageSkeleton, RootContentLoader } from 'shared/components/ui';
import { ROUTES } from 'shared/constants';
import { useCurrentUser, useInitialPreloaderHide, useSchoolConfiguration } from 'shared/hooks';
import { useResponsive } from 'shared/hooks/useResponsive';

const ROUTES_WITHOUT_BREADCRUMBS: string[] = [
  ROUTES.user.home.index,
  ROUTES.user.explorer.profileIndex,
];

export function RootContent() {
  const { isMobile } = useResponsive();
  const { hasSession } = useAppAuth();
  const { leftMenuDrawer } = useGlobalState();
  const { loading: userLoading, isAdmin } = useCurrentUser();
  const { data: school, loading: schoolLoading } = useSchoolConfiguration();
  const { onChangeTheme } = useCustomTheme();
  const { pathname } = useLocation();

  const loading = useMemo(() => {
    return userLoading || schoolLoading;
  }, [userLoading, schoolLoading]);

  useInitialPreloaderHide();

  const isAdminRoute = useMemo(() => pathname.startsWith('/admin'), [pathname]);

  const withBreadcrumbs = useMemo(() => {
    return ROUTES_WITHOUT_BREADCRUMBS.includes(pathname);
  }, [pathname]);

  const routes = useMemo(() => {
    if (loading) {
      return [];
    }

    return isAdmin ? [...adminRoutes, ...userRoutes] : userRoutes;
  }, [loading, isAdmin]);

  const redirectTo = useMemo(() => {
    if (!hasSession) {
      return ROUTES.public.login;
    }

    if (isAdminRoute) {
      return ROUTES.admin.home.index;
    }

    return ROUTES.user.home.index;
  }, [hasSession, isAdminRoute]);

  useLayoutEffect(() => {
    /**
     * Scroll up when the page change
     * https://8base-dev.atlassian.net/browse/JEB-1262
     */
    document.documentElement.scrollTo({ top: 0 });
  }, [pathname]);

  useLayoutEffect(() => {
    if (school?.colorSchema) {
      onChangeTheme(school?.colorSchema);
    }
  }, [school?.colorSchema]);

  if (!hasSession && loading) {
    return <RootContentLoader />;
  }

  return (
    <Fragment>
      <MarkPostModal />
      <EditPostModal />
      <Switch>
        <Route path={[ROUTES.public.auth, ROUTES.public.register]}>
          <OnboardingPagesRouter />
        </Route>

        <PageLayout
          content={
            <Switch>
              {routes.map(
                ({ routeComponent: RouteComponent = Route, component, path, ...rest }) => (
                  <RouteComponent
                    key={Array.isArray(path) ? path[0] : path}
                    path={path}
                    component={component}
                    {...rest}
                  />
                ),
              )}

              {loading ? (
                isAdminRoute ? (
                  <DashboardPageSkeleton />
                ) : (
                  <FeedPageSkeleton />
                )
              ) : (
                <Redirect to={redirectTo} />
              )}
            </Switch>
          }
          sidebar={isMobile ? <SidebarNavigationMobile /> : <SidebarNavigation />}
          bottomMenu={<BottomNavigation key="bottomNavigationWrap" />}
          header={
            isMobile ? (
              <HeaderNavigationMobile isAdminRoute={isAdminRoute} />
            ) : (
              <HeaderNavigation isAdminRoute={isAdminRoute} withBreadcrumbs={withBreadcrumbs} />
            )
          }
          drawerOpen={leftMenuDrawer.isOpened}
          drawerOnClose={leftMenuDrawer.close}
          drawerAnchor="left"
          drawerVariant="permanent"
          drawerOpenWidth={SIDEBAR_OPEN_WIDTH}
          drawerClosedWidth={SIDEBAR_CLOSED_WIDTH}
        />
      </Switch>
    </Fragment>
  );
}
