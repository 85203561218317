import { isFutureDate, isPastDate, parseDate } from '../dates';
import { isEmail, isFn } from '../types';
/** Compose multiple {@linkcode FormFieldValidation} functions into a single one. */
export function composeFieldValidators(...validations) {
    const filtered = validations.filter(isFn);
    return value => {
        for (const fn of filtered) {
            const feedback = fn(value);
            if (feedback instanceof Promise) {
                return feedback;
            }
            if (typeof feedback === 'string') {
                return feedback;
            }
        }
        return undefined;
    };
}
/** Checks whether the given field is a date-time in the future. */
export const shouldBeDateTimeField = value => {
    const parsed = parseDate(value);
    if (!parsed.isValid) {
        return `Should be a date-time`;
    }
    return undefined;
};
/** Checks whether the given field is a date-time in the future. */
export const shouldBeFutureDateTimeField = value => {
    const parsed = parseDate(value);
    if (!parsed.isValid) {
        return `Should be a date-time`;
    }
    if (isPastDate(parsed)) {
        return `Should be a date in the future`;
    }
    return undefined;
};
/** Checks whether the given field is a date-time in the past. */
export const shouldBePastDateTimeField = value => {
    const parsed = parseDate(value);
    if (!parsed.isValid) {
        return `Should be a date-time`;
    }
    if (isFutureDate(parsed)) {
        return `Should be a date in the past`;
    }
    return undefined;
};
export const PASSWORD_FORMAT_REGEX = 
// eslint-disable-next-line no-useless-escape
/^(?=.*[0-9])(?=.*[!@#$%^&*\-\(\)])[a-zA-Z0-9!@#$%^&*\-\(\)]{8,}$/i;
/**
 * Validates if the given value meets the password format requirements.
 *
 * The password must contain at least one uppercase letter, one lowercase letter,
 * one number, and one special character.
 *
 * @param value - The password string to validate.
 * @returns A validation error message if the password does not meet the requirements, otherwise undefined.
 */
export const shouldBePassword = value => {
    if (!PASSWORD_FORMAT_REGEX.test(value)) {
        return 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character';
    }
    return undefined;
};
/**
 * Validates whether a given string is a valid email address.
 *
 * @param value - The string to be validated as an email address.
 * @returns A string with an error message if the validation fails, otherwise undefined.
 */
export const shouldBeEmail = value => {
    if (!isEmail(value)) {
        return `"${value}" is not a valid email address`;
    }
    return undefined;
};
