import { MutationHookOptions } from '@apollo/client';

import {
  useSendSchoolMemberInvitesMutation,
  useSendBusinessManagerInvitesMutation,
  useSendSchoolAdministratorInvitesMutation,
  SendSchoolMemberInviteInput,
  SendSchoolAdministratorInviteInput,
  SendBusinessManagerInviteInput,
  useSendBusinessAdministratorInvitesMutation,
  SendBusinessAdministratorInviteInput,
} from 'shared/graphql';

import { useCurrentUserPermissions } from './useCurrentUserPermissions';
import { useSchoolConfiguration } from './useSchoolConfiguration';

type Options = Omit<MutationHookOptions, 'variables'>;

/**
 * Custom hook for managing user invitations across different roles and contexts.
 *
 * This hook encapsulates several GraphQL mutations for inviting users as either:
 * - School members (with an optional automatic school administrator approval based on user permissions),
 * - School administrators,
 * - Business managers, and
 * - Business administrators.
 *
 * It leverages the current user's permissions to determine if school administrators are allowed to directly invite,
 * and uses the school configuration for context-specific operations.
 *
 * @param options - Optional configuration options for the GraphQL mutations.
 */
export function useUserInvitation(options?: Options) {
  const { isSchoolAdmin: isAdmin } = useCurrentUserPermissions();
  const { data: school } = useSchoolConfiguration();

  const [sendSchoolMemberInvitation, { loading: isSendingSchoolMemberInvites }] =
    useSendSchoolMemberInvitesMutation(options);

  const [sendSchoolAdminInvitation, { loading: isSendingSchoolAdminInvites }] =
    useSendSchoolAdministratorInvitesMutation(options);

  const [sendBusinessManagerInvite, { loading: isSendingBusinessManagerInvites }] =
    useSendBusinessManagerInvitesMutation(options);

  const [sendBusinessAdminInvitation, { loading: isSendingBusinessAdminInvites }] =
    useSendBusinessAdministratorInvitesMutation(options);

  const isInviting =
    isSendingSchoolMemberInvites ||
    isSendingSchoolAdminInvites ||
    isSendingBusinessManagerInvites ||
    isSendingBusinessAdminInvites;

  return {
    isInviting,

    /** Send the invitations to another users. In case `isAdminInviting` and indeed has the rights will approve those users. */
    async inviteUsers(invites: SendSchoolMemberInviteInput[], isAdminInviting = isAdmin) {
      await sendSchoolMemberInvitation({
        variables: {
          invites,
          school: { id: school?.id },
          isSchoolAdministratorInviting: isAdminInviting,
        },
      });
    },

    /** Send the invitations to another users. In case `isAdminInviting` and indeed has the rights will approve those users. */
    async inviteAdmins(invites: SendSchoolAdministratorInviteInput[]) {
      await sendSchoolAdminInvitation({
        variables: {
          invites,
          school: { id: school?.id },
        },
      });
    },

    /** Invite users as business managers to a given `business` and approve their incorporation. */
    async inviteBusinessManagers(invites: SendBusinessManagerInviteInput[], businessId: string) {
      return sendBusinessManagerInvite({
        variables: {
          invites,
          organization: { id: businessId },
        },
      });
    },

    /** Invite users as business administrators and approve their incorporation. */
    async inviteBusinessAdmins(invites: SendBusinessAdministratorInviteInput[]) {
      await sendBusinessAdminInvitation({
        variables: {
          invites,
          school: { id: school?.id },
        },
      });
    },
  };
}
