import { createFilterBuilder } from '@jebel/utils';

import {
  useAcceptSchoolMemberMutation,
  UserSchoolMembershipFilter,
  UserKeyFilter,
} from 'shared/graphql';
import { useSchoolConfiguration, useToast } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

const APPROVING_MEMBER_MESSAGE_KEY = 'APPROVING_MEMBER_MESSAGE';

type Options = Parameters<typeof useAcceptSchoolMemberMutation>[0];

/**
 * Custom hook to accept a school member.
 *
 * This hook provides a function to approve a member for a school and handles
 * success and error notifications.
 *
 * @returns An object containing:
 * - `mutate`: A function to approve a member.
 * - `loading`: A boolean indicating if the mutation is in progress.
 *
 * @example
 * const { mutate, loading } = useAcceptMember();
 *
 * const handleApprove = async (user) => {
 *   await mutate(user);
 * };
 */
export function useAcceptMember(options?: Options) {
  const { showMessage, showSuccess, showError, dismiss } = useToast();
  const { data: school } = useSchoolConfiguration();

  const [mutation, { loading }] = useAcceptSchoolMemberMutation(options);

  const mutate = async (user: UserKeyFilter) => {
    if (!school) {
      return;
    }

    try {
      showMessage(`Approving member "${user.email ?? user.id}".`, {
        id: APPROVING_MEMBER_MESSAGE_KEY,
      });

      const filter = createFilterBuilder<UserSchoolMembershipFilter>();

      if (user.id) {
        filter.or({ user: { id: { equals: user.id } } });
      }

      if (user.email) {
        filter.or({ user: { email: { equals: user.email } } });
      }

      await mutation({
        variables: {
          filter: filter.build(),
          school: { id: school.id },
        },
      });

      showSuccess(`Member "${user.email ?? user.id}" has been approved`);
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message, { reportable: false });
      }
    } finally {
      dismiss(APPROVING_MEMBER_MESSAGE_KEY);
    }
  };

  return {
    mutate,
    loading,
  };
}
