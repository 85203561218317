import { usePromoteSchoolMemberMutation, UserKeyFilter } from 'shared/graphql';
import { useSchoolConfiguration, useToast } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

const PROMOTING_MEMBER_MESSAGE_KEY = 'PROMOTING_MEMBER_MESSAGE';

type Options = Parameters<typeof usePromoteSchoolMemberMutation>[0];

/**
 * Hook to promote a regular member to a school administrator role.
 *
 * @param options - Optional configuration options for the mutation.
 *
 * @remarks
 * - Requires school configuration to be available
 * - Displays toast notifications during the promotion process
 * - Automatically handles error reporting
 */
export function usePromoteMember(options?: Options) {
  const { showMessage, showSuccess, showError, dismiss } = useToast();
  const { data: school } = useSchoolConfiguration();

  const [mutation, { loading }] = usePromoteSchoolMemberMutation(options);

  const mutate = async (user: UserKeyFilter) => {
    if (!school) {
      return;
    }

    try {
      showMessage(`Promoting member "${user.email ?? user.id}".`, {
        id: PROMOTING_MEMBER_MESSAGE_KEY,
      });

      await mutation({
        variables: {
          user: { id: user.id, email: user.email },
          school: { id: school.id },
        },
      });

      showSuccess(`Member "${user.email ?? user.id}" has been promoted to school administrator.`);
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message, { reportable: false });
      }
    } finally {
      dismiss(PROMOTING_MEMBER_MESSAGE_KEY);
    }
  };

  return {
    mutate,
    loading,
  };
}
