import posthog from 'posthog-js';
import { useEffect, useState } from 'react';

import { recordDebug } from 'shared/utils/record';

const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';

interface UseFeatureEnabled {
  /**
   * Whether the feature is enabled.
   * On development mode, will always return `true`.
   * @default false
   */
  enabled: boolean;

  /**
   * Whether the feature is loading.
   * @default true
   */
  loading: boolean;

  /**
   * Refetch the feature flag status.
   */
  refetch(): void;
}

export type FeatureEnabledKey = 'showSchoolSwitch';

/**
 * Custom hook to check if a feature flag is enabled.
 *
 * @param {string} key - The key of the feature flag to check.
 *
 * @example
 * const { enabled, refetch } = useFeatureEnabled('my-feature-flag');
 *
 * useEffect(() => {
 *   if (enabled) {
 *     // Feature is enabled, perform some action
 *   }
 * }, [enabled]);
 */
export function useFeatureEnabled(key: FeatureEnabledKey): UseFeatureEnabled {
  const [enabled, setEnabled] = useState(IS_DEVELOPMENT);
  const [loading, setLoading] = useState(true);

  const refetch = () => {
    const active = posthog.isFeatureEnabled(key);

    recordDebug(`Feature flag "${key}" is ${active ? 'enabled' : 'disabled'}`);

    setEnabled(active ?? IS_DEVELOPMENT);
    setLoading(false);
  };

  useEffect(() => {
    return posthog.onFeatureFlags(() => refetch());
  }, []);

  return {
    enabled,
    loading,
    refetch,
  };
}
