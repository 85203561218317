import { useMemo } from 'react';

import { useContentCurationCountQuery } from 'shared/graphql';
import { useSchoolConfiguration } from 'shared/hooks';

export function useContentCurationCount() {
  const { data: school } = useSchoolConfiguration();
  const { data: response, loading } = useContentCurationCountQuery({
    skip: !school,
    // Filter by the current school.
    // https://github.com/jebelapp/jebel/issues/1723#issuecomment-2724954134
    variables: { school: school?.id as string },
  });

  const data = useMemo(() => {
    const pending = response?.pending.count ?? 0;

    return {
      pending,
    };
  }, [response]);

  return {
    data,
    loading,
  };
}
