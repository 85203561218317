import { useMemo } from 'react';

import { CommunityStatus } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import { useAppAuth } from 'providers';
import { SchoolConfigurationFilter } from 'shared/graphql';

import { useCurrentUserRoles } from './useCurrentUserRoles';
import { useFeatureEnabled } from './useFeatureEnabled';
import { useSchoolConfiguration } from './useSchoolConfiguration';
import { useSchoolConfigurations } from './useSchoolConfigurations';

type Options = Parameters<typeof useSchoolConfigurations>[0] & {
  /**
   * Exclude the current school from the results.
   * @default false
   */
  skipCurrentSchool?: boolean;
};

/**
 * Custom hook to fetch the current user's schools.
 *
 * This hook uses the `useSchoolConfigurations` query to retrieve the school configurations associated with the current user.
 * @ticket https://github.com/jebelapp/jebel/issues/1562
 */
export function useCurrentUserSchools(options?: Options) {
  const { hasSession } = useAppAuth();
  const { data: currentSchool } = useSchoolConfiguration();
  const { isPlatformAdmin, loading: systemRolesLoading } = useCurrentUserRoles();

  const { enabled: showSchoolSwitch, loading: showSchoolSwitchLoading } =
    useFeatureEnabled('showSchoolSwitch');

  const filter: SchoolConfigurationFilter = useMemo(() => {
    const filter = createFilterBuilder<SchoolConfigurationFilter>(options?.variables?.filter)
      // Only include schools that are active or have been invited.
      // https://github.com/jebelapp/jebel/issues/1727
      // https://github.com/jebelapp/jebel/issues/1723#issuecomment-2758554832
      .and({ status: { in: [CommunityStatus.Active, CommunityStatus.InvitationSent] } });

    if (hasSession && !isPlatformAdmin) {
      // Filter by only the current user's schools.
      // Platform admins can see all schools. See https://github.com/jebelapp/jebel/issues/1730
      filter.and({ userMemberships: { some: { user: { is_self: true } } } });
    }

    if (options?.skipCurrentSchool && currentSchool) {
      // Exclude the current school from the results.
      filter.and({ id: { not_equals: currentSchool.id } });
    }

    return filter.build();
  }, [
    options?.variables?.filter,
    options?.skipCurrentSchool,
    hasSession,
    isPlatformAdmin,
    currentSchool,
  ]);

  const { data, loading } = useSchoolConfigurations({
    ...options,

    skip: showSchoolSwitchLoading || systemRolesLoading || options?.skip,
    variables: {
      ...options?.variables,
      filter,
      // Show all schools when the user is authorized.
      // When the user is not authorized, shows only the first school.
      // https://github.com/jebelapp/jebel/issues/1563#issuecomment-2477519378
      first: showSchoolSwitch ? undefined : 1,
    },
  });

  return {
    data,
    loading,
  };
}
