import { CommunityStatus } from '@jebel/constants';
/**
 * Determines if community can be activated based on their status.
 *
 * @param currentStatus - Status of the community.
 * @returns A boolean indicating whether the community can be activated.
 */
export function canCommunityBeActivated(currentStatus) {
    return currentStatus === CommunityStatus.Inactive;
}
/**
 * Determines if community can be inactivated based on their status.
 *
 * @param currentStatus - Status of the community.
 * @returns A boolean indicating whether the community can be inactivated.
 */
export function canCommunityBeInactivated(currentStatus) {
    return currentStatus === CommunityStatus.Active;
}
