import { SortDirection } from './graphql';
export const DEFAULT_USER_NAME = '(User)';
export const SELECT_OPTION_ALL = 'All';
export const PLATFORM_NAME = 'Jebel';
export const PLATFORM_INBOX_NAME = 'Jebel App';
export const US_STATES = [
    { abbreviation: 'AK', name: 'Alaska' },
    { abbreviation: 'AL', name: 'Alabama' },
    { abbreviation: 'AR', name: 'Arkansas' },
    { abbreviation: 'AZ', name: 'Arizona' },
    { abbreviation: 'CA', name: 'California' },
    { abbreviation: 'CO', name: 'Colorado' },
    { abbreviation: 'CT', name: 'Connecticut' },
    { abbreviation: 'DC', name: 'District of Columbia' },
    { abbreviation: 'DE', name: 'Delaware' },
    { abbreviation: 'FL', name: 'Florida' },
    { abbreviation: 'GA', name: 'Georgia' },
    { abbreviation: 'HI', name: 'Hawaii' },
    { abbreviation: 'IA', name: 'Iowa' },
    { abbreviation: 'ID', name: 'Idaho' },
    { abbreviation: 'IL', name: 'Illinois' },
    { abbreviation: 'IN', name: 'Indiana' },
    { abbreviation: 'KS', name: 'Kansas' },
    { abbreviation: 'KY', name: 'Kentucky' },
    { abbreviation: 'LA', name: 'Louisiana' },
    { abbreviation: 'MA', name: 'Massachusetts' },
    { abbreviation: 'MD', name: 'Maryland' },
    { abbreviation: 'ME', name: 'Maine' },
    { abbreviation: 'MI', name: 'Michigan' },
    { abbreviation: 'MN', name: 'Minnesota' },
    { abbreviation: 'MO', name: 'Missouri' },
    { abbreviation: 'MS', name: 'Mississippi' },
    { abbreviation: 'MT', name: 'Montana' },
    { abbreviation: 'NC', name: 'North Carolina' },
    { abbreviation: 'ND', name: 'North Dakota' },
    { abbreviation: 'NE', name: 'Nebraska' },
    { abbreviation: 'NH', name: 'New Hampshire' },
    { abbreviation: 'NJ', name: 'New Jersey' },
    { abbreviation: 'NM', name: 'New Mexico' },
    { abbreviation: 'NV', name: 'Nevada' },
    { abbreviation: 'NY', name: 'New York' },
    { abbreviation: 'OH', name: 'Ohio' },
    { abbreviation: 'OK', name: 'Oklahoma' },
    { abbreviation: 'OR', name: 'Oregon' },
    { abbreviation: 'PA', name: 'Pennsylvania' },
    { abbreviation: 'PR', name: 'Puerto Rico' },
    { abbreviation: 'RI', name: 'Rhode Island' },
    { abbreviation: 'SC', name: 'South Carolina' },
    { abbreviation: 'SD', name: 'South Dakota' },
    { abbreviation: 'TN', name: 'Tennessee' },
    { abbreviation: 'TX', name: 'Texas' },
    { abbreviation: 'UT', name: 'Utah' },
    { abbreviation: 'VA', name: 'Virginia' },
    { abbreviation: 'VI', name: 'Virgin Islands' },
    { abbreviation: 'VT', name: 'Vermont' },
    { abbreviation: 'WA', name: 'Washington' },
    { abbreviation: 'WI', name: 'Wisconsin' },
    { abbreviation: 'WV', name: 'West Virginia' },
    { abbreviation: 'WY', name: 'Wyoming' },
];
export const DISCOUNT_CATEGORIES = {
    automotive: 'automotive',
    dining: 'dining',
    entertainment: 'entertainment',
    financial: 'financial',
    sportsFitness: 'sportsFitness',
    insurance: 'insurance',
    technology: 'technology',
    businessSupport: 'businessSupport',
    marketing: 'marketing',
    construction: 'construction',
    education: 'education',
    medical: 'medical',
    dental: 'dental',
    homeGarden: 'homeGarden',
    legal: 'legal',
    retail: 'retail',
    realEstate: 'realEstate',
    personalCare: 'personalCare',
    travel: 'travel',
    distribution: 'distribution',
    other: 'other',
};
export const INDUSTRIES = {
    BusinessSupport: 'businessSupport',
    Construction: 'construction',
    Dental: 'dental',
    Dining: 'dining',
    Distribution: 'distribution',
    Education: 'education',
    Entertainment: 'entertainment',
    Financial: 'financial',
    HomeGarden: 'homeGarden',
    Insurance: 'insurance',
    Legal: 'legal',
    Marketing: 'marketing',
    Medical: 'medical',
    PersonalCare: 'personalCare',
    RealEstate: 'realEstate',
    Retail: 'retail',
    SportsFitness: 'sportsFitness',
    Technology: 'technology',
    Travel: 'travel',
    accounting: 'accounting',
    airlines: 'airlines',
    alternativeDisputeResolution: 'alternativeDisputeResolution',
    alternativeMedicine: 'alternativeMedicine',
    animation: 'animation',
    apparelFashion: 'apparelFashion',
    architecturePlanning: 'architecturePlanning',
    artsCrafts: 'artsCrafts',
    automotive: 'automotive',
    aviationAerospace: 'aviationAerospace',
    banking: 'banking',
    biotechnology: 'banking',
    broadcastMedia: 'broadcastMedia',
    buildingMaterials: 'broadcastMedia',
    businessSuppliesEquipment: 'businessSuppliesEquipment',
    capitalMarkets: 'capitalMarkets',
    chemicals: 'chemicals',
    civicSocialOrganization: 'civicSocialOrganization',
    civilEngineering: 'civilEngineering',
    commercialRealEstate: 'civilEngineering',
    computerNetworkSecurity: 'computerNetworkSecurity',
    computerGames: 'computerGames',
    computerHardware: 'computerHardware',
    computerNetworking: 'computerNetworking',
    computerSoftware: 'computerSoftware',
    construction: 'construction',
    consumerElectronics: 'consumerElectronics',
    consumerGoods: 'consumerElectronics',
    consumerServices: 'consumerServices',
    cosmetics: 'cosmetics',
    dairy: 'dairy',
    defenseSpace: 'defenseSpace',
    design: 'design',
    educationManagement: 'educationManagement',
    eLearning: 'eLearning',
    electricalElectronicManufacturing: 'electricalElectronicManufacturing',
    entertainment: 'entertainment',
    environmentalServices: 'environmentalServices',
    eventsServices: 'eventsServices',
    executiveOffice: 'executiveOffice',
    facilitiesServices: 'facilitiesServices',
    farming: 'farming',
    financialServices: 'financialServices',
    fineArt: 'fineArt',
    fishery: 'fishery',
    foodBeverages: 'foodBeverages',
    foodProduction: 'foodProduction',
    fundRaising: 'foodProduction',
    furniture: 'foodProduction',
    gamblingCasinos: 'gamblingCasinos',
    glassCeramicsConcrete: 'glassCeramicsConcrete',
    governmentAdministration: 'glassCeramicsConcrete',
    governmentRelations: 'glassCeramicsConcrete',
    graphicDesign: 'glassCeramicsConcrete',
    healthWellnessFitness: 'healthWellnessFitness',
    higherEducation: 'higherEducation',
    hospitalHealthCare: 'hospitalHealthCare',
    hospitality: 'hospitality',
    humanResources: 'humanResources',
    importExport: 'humanResources',
    individualFamilyServices: 'individualFamilyServices',
    industrialAutomation: 'industrialAutomation',
    informationServices: 'industrialAutomation',
    informationTechnologyServices: 'informationTechnologyServices',
    insurance: 'insurance',
    internationalAffairs: 'internationalAffairs',
    internationalTradeDevelopment: 'internationalAffairs',
    internet: 'internationalAffairs',
    investmentBanking: 'internationalAffairs',
    investmentManagement: 'internationalAffairs',
    judiciary: 'judiciary',
    lawEnforcement: 'lawEnforcement',
    lawPractice: 'lawEnforcement',
    legalServices: 'lawEnforcement',
    legislativeOffice: 'legislativeOffice',
    leisureTravelTourism: 'leisureTravelTourism',
    libraries: 'libraries',
    logisticsSupplyChain: 'logisticsSupplyChain',
    luxuryGoodsJewelry: 'logisticsSupplyChain',
    machinery: 'machinery',
    managementConsulting: 'managementConsulting',
    maritime: 'maritime',
    marketResearch: 'marketResearch',
    marketingAdvertising: 'marketingAdvertising',
    mechanicalIndustrialEngineering: 'mechanicalIndustrialEngineering',
    mediaProduction: 'mediaProduction',
    medicalDevices: 'medicalDevices',
    medicalPractice: 'medicalPractice',
    mentalHealthCare: 'mentalHealthCare',
    military: 'military',
    miningMetals: 'miningMetals',
    motionPicturesFilm: 'motionPicturesFilm',
    museumsInstitutions: 'museumsInstitutions',
    music: 'music',
    nanotechnology: 'nanotechnology',
    newspapers: 'newspapers',
    nonProfitOrganizationManagement: 'nonProfitOrganizationManagement',
    oilEnergy: 'oilEnergy',
    onlineMedia: 'onlineMedia',
    outsourcingOffshoring: 'outsourcingOffshoring',
    packageFreightDelivery: 'packageFreightDelivery',
    packagingContainers: 'packagingContainers',
    paperForestProducts: 'paperForestProducts',
    performingArts: 'performingArts',
    pharmaceuticals: 'pharmaceuticals',
    philanthropy: 'philanthropy',
    photography: 'photography',
    plastics: 'plastics',
    politicalOrganization: 'politicalOrganization',
    primarySecondaryEducation: 'primarySecondaryEducation',
    printing: 'printing',
    professionalTrainingCoaching: 'professionalTrainingCoaching',
    programDevelopment: 'programDevelopment',
    publicPolicy: 'publicPolicy',
    publicRelationsCommunications: 'publicRelationsCommunications',
    publicSafety: 'publicSafety',
    publishing: 'publishing',
    railroadManufacture: 'railroadManufacture',
    ranching: 'ranching',
    realEstate: 'realEstate',
    recreationalFacilitiesServices: 'recreationalFacilitiesServices',
    religiousInstitutions: 'religiousInstitutions',
    renewablesEnvironment: 'renewablesEnvironment',
    research: 'research',
    restaurants: 'restaurants',
    retail: 'retail',
    securityInvestigations: 'securityInvestigations',
    semiconductors: 'semiconductors',
    shipbuilding: 'shipbuilding',
    sportingGoods: 'sportingGoods',
    sports: 'sports',
    staffingRecruiting: 'staffingRecruiting',
    supermarkets: 'supermarkets',
    telecommunications: 'telecommunications',
    textiles: 'textiles',
    thinkTanks: 'textiles',
    tobacco: 'tobacco',
    translationLocalization: 'translationLocalization',
    transportationTruckingRailroad: 'transportationTruckingRailroad',
    utilities: 'utilities',
    ventureCapitalPrivateEquity: 'ventureCapitalPrivateEquity',
    veterinary: 'veterinary',
    warehousing: 'warehousing',
    wholesale: 'wholesale',
    wineSpirits: 'wineSpirits',
    wireless: 'wireless',
    writingEditing: 'writingEditing',
};
export const INDUSTRIES_OPTIONS = [
    { value: SELECT_OPTION_ALL, label: SELECT_OPTION_ALL },
    { value: INDUSTRIES.accounting, label: 'Accounting' },
    { value: INDUSTRIES.alternativeDisputeResolution, label: 'Airlines/Aviation' },
    { value: INDUSTRIES.automotive, label: 'Alternative Dispute Resolution' },
    { value: INDUSTRIES.alternativeMedicine, label: 'Alternative Medicine' },
    { value: INDUSTRIES.animation, label: 'Animation' },
    { value: INDUSTRIES.apparelFashion, label: 'Apparel & Fashion' },
    { value: INDUSTRIES.architecturePlanning, label: 'Architecture & Planning' },
    { value: INDUSTRIES.artsCrafts, label: 'Arts and Crafts' },
    { value: INDUSTRIES.automotive, label: 'Automotive' },
    { value: INDUSTRIES.aviationAerospace, label: 'Aviation & Aerospace' },
    { value: INDUSTRIES.banking, label: 'Banking' },
    { value: INDUSTRIES.biotechnology, label: 'Biotechnology' },
    { value: INDUSTRIES.broadcastMedia, label: 'Broadcast Media' },
    { value: INDUSTRIES.buildingMaterials, label: 'Building Materials' },
    { value: INDUSTRIES.businessSuppliesEquipment, label: 'Business Supplies and Equipment' },
    { value: INDUSTRIES.capitalMarkets, label: 'Capital Markets' },
    { value: INDUSTRIES.chemicals, label: 'Chemicals' },
    { value: INDUSTRIES.civicSocialOrganization, label: 'Civic & Social Organization' },
    { value: INDUSTRIES.civilEngineering, label: 'Civil Engineering' },
    { value: INDUSTRIES.commercialRealEstate, label: 'Commercial Real Estate' },
    { value: INDUSTRIES.computerNetworkSecurity, label: 'Computer & Network Security' },
    { value: INDUSTRIES.computerGames, label: 'Computer Games' },
    { value: INDUSTRIES.computerHardware, label: 'Computer Hardware' },
    { value: INDUSTRIES.computerNetworking, label: 'Computer Networking' },
    { value: INDUSTRIES.computerSoftware, label: 'Computer Software' },
    { value: INDUSTRIES.construction, label: 'Construction' },
    { value: INDUSTRIES.consumerElectronics, label: 'Consumer Electronics' },
    { value: INDUSTRIES.consumerGoods, label: 'Consumer Goods' },
    { value: INDUSTRIES.consumerServices, label: 'Consumer Services' },
    { value: INDUSTRIES.cosmetics, label: 'Cosmetics' },
    { value: INDUSTRIES.dairy, label: 'Dairy' },
    { value: INDUSTRIES.defenseSpace, label: 'Defense & Space' },
    { value: INDUSTRIES.design, label: 'Design' },
    { value: INDUSTRIES.educationManagement, label: 'Education Management' },
    { value: INDUSTRIES.eLearning, label: 'E-Learning' },
    {
        value: INDUSTRIES.electricalElectronicManufacturing,
        label: 'Electrical/Electronic Manufacturing',
    },
    { value: INDUSTRIES.entertainment, label: 'Entertainment' },
    { value: INDUSTRIES.environmentalServices, label: 'Environmental Services' },
    { value: INDUSTRIES.eventsServices, label: 'Events Services' },
    { value: INDUSTRIES.executiveOffice, label: 'Executive Office' },
    { value: INDUSTRIES.facilitiesServices, label: 'Facilities Services' },
    { value: INDUSTRIES.farming, label: 'Farming' },
    { value: INDUSTRIES.financialServices, label: 'Financial Services' },
    { value: INDUSTRIES.fineArt, label: 'Fine Art' },
    { value: INDUSTRIES.fishery, label: 'Fishery' },
    { value: INDUSTRIES.foodBeverages, label: 'Food & Beverages' },
    { value: INDUSTRIES.foodProduction, label: 'Food Production' },
    { value: INDUSTRIES.fundRaising, label: 'Fund-Raising' },
    { value: INDUSTRIES.furniture, label: 'Furniture' },
    { value: INDUSTRIES.gamblingCasinos, label: 'Gambling & Casinos' },
    { value: INDUSTRIES.glassCeramicsConcrete, label: 'Glass, Ceramics & Concrete' },
    { value: INDUSTRIES.governmentAdministration, label: 'Government Administration' },
    { value: INDUSTRIES.governmentRelations, label: 'Government Relations' },
    { value: INDUSTRIES.graphicDesign, label: 'Graphic Design' },
    { value: INDUSTRIES.healthWellnessFitness, label: 'Health, Wellness and Fitness' },
    { value: INDUSTRIES.higherEducation, label: 'Higher Education' },
    { value: INDUSTRIES.hospitalHealthCare, label: 'Hospital & Health Care' },
    { value: INDUSTRIES.hospitality, label: 'Hospitality' },
    { value: INDUSTRIES.humanResources, label: 'Human Resources' },
    { value: INDUSTRIES.importExport, label: 'Import and Export' },
    { value: INDUSTRIES.individualFamilyServices, label: 'Individual & Family Services' },
    { value: INDUSTRIES.industrialAutomation, label: 'Industrial Automation' },
    { value: INDUSTRIES.informationServices, label: 'Information Services' },
    {
        value: INDUSTRIES.informationTechnologyServices,
        label: 'Information Technology and Services',
    },
    { value: INDUSTRIES.insurance, label: 'Insurance' },
    { value: INDUSTRIES.internationalAffairs, label: 'International Affairs' },
    {
        value: INDUSTRIES.internationalTradeDevelopment,
        label: 'International Trade and Development',
    },
    { value: INDUSTRIES.internet, label: 'Internet' },
    { value: INDUSTRIES.investmentBanking, label: 'Investment Banking' },
    { value: INDUSTRIES.investmentManagement, label: 'Investment Management' },
    { value: INDUSTRIES.judiciary, label: 'Judiciary' },
    { value: INDUSTRIES.lawEnforcement, label: 'Law Enforcement' },
    { value: INDUSTRIES.lawPractice, label: 'Law Practice' },
    { value: INDUSTRIES.legalServices, label: 'Legal Services' },
    { value: INDUSTRIES.legislativeOffice, label: 'Legislative Office' },
    { value: INDUSTRIES.leisureTravelTourism, label: 'Leisure, Travel & Tourism' },
    { value: INDUSTRIES.libraries, label: 'Libraries' },
    { value: INDUSTRIES.logisticsSupplyChain, label: 'Logistics and Supply Chain' },
    { value: INDUSTRIES.luxuryGoodsJewelry, label: 'Luxury Goods & Jewelry' },
    { value: INDUSTRIES.machinery, label: 'Machinery' },
    { value: INDUSTRIES.managementConsulting, label: 'Management Consulting' },
    { value: INDUSTRIES.maritime, label: 'Maritime' },
    { value: INDUSTRIES.marketResearch, label: 'Market Research' },
    { value: INDUSTRIES.marketingAdvertising, label: 'Marketing and Advertising' },
    {
        value: INDUSTRIES.mechanicalIndustrialEngineering,
        label: 'Mechanical or Industrial Engineering',
    },
    { value: INDUSTRIES.mediaProduction, label: 'Media Production' },
    { value: INDUSTRIES.medicalDevices, label: 'Medical Devices' },
    { value: INDUSTRIES.medicalPractice, label: 'Medical Practice' },
    { value: INDUSTRIES.mentalHealthCare, label: 'Mental Health Care' },
    { value: INDUSTRIES.military, label: 'Military' },
    { value: INDUSTRIES.miningMetals, label: 'Mining & Metals' },
    { value: INDUSTRIES.motionPicturesFilm, label: 'Motion Pictures and Film' },
    { value: INDUSTRIES.museumsInstitutions, label: 'Museums and Institutions' },
    { value: INDUSTRIES.music, label: 'Music' },
    { value: INDUSTRIES.nanotechnology, label: 'Nanotechnology' },
    { value: INDUSTRIES.newspapers, label: 'Newspapers' },
    {
        value: INDUSTRIES.nonProfitOrganizationManagement,
        label: 'Non-Profit Organization Management',
    },
    { value: INDUSTRIES.oilEnergy, label: 'Oil & Energy' },
    { value: INDUSTRIES.onlineMedia, label: 'Online Media' },
    { value: INDUSTRIES.outsourcingOffshoring, label: 'Outsourcing/Offshoring' },
    { value: INDUSTRIES.packageFreightDelivery, label: 'Package/Freight Delivery' },
    { value: INDUSTRIES.packagingContainers, label: 'Packaging and Containers' },
    { value: INDUSTRIES.paperForestProducts, label: 'Paper & Forest Products' },
    { value: INDUSTRIES.performingArts, label: 'Performing Arts' },
    { value: INDUSTRIES.pharmaceuticals, label: 'Pharmaceuticals' },
    { value: INDUSTRIES.philanthropy, label: 'Philanthropy' },
    { value: INDUSTRIES.photography, label: 'Photography' },
    { value: INDUSTRIES.plastics, label: 'Plastics' },
    { value: INDUSTRIES.politicalOrganization, label: 'Political Organization' },
    { value: INDUSTRIES.primarySecondaryEducation, label: 'Primary/Secondary Education' },
    { value: INDUSTRIES.printing, label: 'Printing' },
    { value: INDUSTRIES.professionalTrainingCoaching, label: 'Professional Training & Coaching' },
    { value: INDUSTRIES.programDevelopment, label: 'Program Development' },
    { value: INDUSTRIES.publicPolicy, label: 'Public Policy' },
    {
        value: INDUSTRIES.publicRelationsCommunications,
        label: 'Public Relations and Communications',
    },
    { value: INDUSTRIES.publicSafety, label: 'Public Safety' },
    { value: INDUSTRIES.publishing, label: 'Publishing' },
    { value: INDUSTRIES.railroadManufacture, label: 'Railroad Manufacture' },
    { value: INDUSTRIES.ranching, label: 'Ranching' },
    { value: INDUSTRIES.realEstate, label: 'Real Estate' },
    {
        value: INDUSTRIES.recreationalFacilitiesServices,
        label: 'Recreational Facilities and Services',
    },
    { value: INDUSTRIES.religiousInstitutions, label: 'Religious Institutions' },
    { value: INDUSTRIES.renewablesEnvironment, label: 'Renewables & Environment' },
    { value: INDUSTRIES.research, label: 'Research' },
    { value: INDUSTRIES.restaurants, label: 'Restaurants' },
    { value: INDUSTRIES.retail, label: 'Retail' },
    { value: INDUSTRIES.securityInvestigations, label: 'Security and Investigations' },
    { value: INDUSTRIES.semiconductors, label: 'Semiconductors' },
    { value: INDUSTRIES.shipbuilding, label: 'Shipbuilding' },
    { value: INDUSTRIES.sportingGoods, label: 'Sporting Goods' },
    { value: INDUSTRIES.sports, label: 'Sports' },
    { value: INDUSTRIES.staffingRecruiting, label: 'Staffing and Recruiting' },
    { value: INDUSTRIES.supermarkets, label: 'Supermarkets' },
    { value: INDUSTRIES.telecommunications, label: 'Telecommunications' },
    { value: INDUSTRIES.textiles, label: 'Textiles' },
    { value: INDUSTRIES.thinkTanks, label: 'Think Tanks' },
    { value: INDUSTRIES.tobacco, label: 'Tobacco' },
    { value: INDUSTRIES.translationLocalization, label: 'Translation and Localization' },
    { value: INDUSTRIES.transportationTruckingRailroad, label: 'Transportation/Trucking/Railroad' },
    { value: INDUSTRIES.utilities, label: 'Utilities' },
    { value: INDUSTRIES.ventureCapitalPrivateEquity, label: 'Venture Capital & Private Equity' },
    { value: INDUSTRIES.veterinary, label: 'Veterinary' },
    { value: INDUSTRIES.warehousing, label: 'Warehousing' },
    { value: INDUSTRIES.wholesale, label: 'Wholesale' },
    { value: INDUSTRIES.wineSpirits, label: 'Wine and Spirits' },
    { value: INDUSTRIES.wireless, label: 'Wireless' },
    { value: INDUSTRIES.writingEditing, label: 'Writing and Editing' },
];
/** @deprecated Use {@linkcode SortDirection} instead. */
export const SORT = {
    asc: SortDirection.ASC,
    desc: SortDirection.DESC,
};
export const US_TIMEZONES = [
    {
        shortName: 'America/Cancun',
        longName: 'Eastern Standard Time',
        abbreviation: 'EST',
    },
    {
        shortName: 'America/Regina',
        longName: 'Central Standard Time',
        abbreviation: 'CST',
    },
    {
        shortName: 'America/Phoenix',
        longName: 'Mountain Standard Time',
        abbreviation: 'MST',
    },
    {
        shortName: 'US/Pacific',
        longName: 'Pacific Standard Time',
        abbreviation: 'PST',
    },
    {
        shortName: 'US/Alaska',
        longName: 'Alaska Standard Time',
        abbreviation: 'AKST',
    },
    {
        shortName: 'US/Hawaii',
        longName: 'Hawaii Standard Time',
        abbreviation: 'HST',
    },
];
export const DISTANCE_FILTER_IN_MILES = {
    any: '-',
    close3: '5 miles',
    close2: '10 miles',
    close1: '20 miles',
    nearby3: '30 miles',
    nearby2: '40 miles',
    nearby1: '50 miles',
    far: '100 miles',
};
export const PHONE_MASK_WILDCARD = '#';
export const PHONE_MASK = '###-###-####';
/**
 * Regular expression to validate email addresses.
 * This regex checks for a valid email format, including the presence of an "@" symbol, a domain name, and a top-level domain (TLD) with at least two characters.
 */
export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
