import { Fragment } from 'react';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { useConfirmDialog } from 'react-mui-confirm';

import { SpreadsheetCellActions, SpreadsheetIdentifiedDataType } from 'shared/features/spreadsheet';
import { usePopoverState } from 'shared/hooks';
import { MoreVert } from '@material-ui/icons';

export type SpreadsheetActionsPopoverProps<
  T extends SpreadsheetIdentifiedDataType = SpreadsheetIdentifiedDataType,
> = {
  cellActions?: SpreadsheetCellActions;
  row: T;
};

export function SpreadsheetActionsPopover<T extends SpreadsheetIdentifiedDataType>({
  cellActions = [],
  row,
}: SpreadsheetActionsPopoverProps<T>) {
  const confirm = useConfirmDialog();
  const { show: isOpen, anchor, open, close } = usePopoverState<HTMLButtonElement>();

  return (
    <Fragment>
      <IconButton onClick={open}>
        <MoreVert fontSize="inherit" color="inherit" />
      </IconButton>

      <Menu open={isOpen} anchorEl={anchor} onClose={close}>
        {cellActions.map(
          ({ id, onClickAction, confirm: confirmOptions, icon, title, checkHidden }) => {
            const isHidden = checkHidden?.(row.id) ?? false;

            const onClick = async () => {
              if (confirmOptions) {
                confirm({
                  title: confirmOptions.text,
                  confirmButtonText: confirmOptions.confirmText,
                  cancelButtonText: confirmOptions.cancelText,
                  description: confirmOptions.description,
                  dialogContentProps: confirmOptions.description
                    ? undefined
                    : {
                        sx: {
                          display: 'none',
                        },
                      },
                  confirmButtonProps: {
                    variant: 'contained',
                  },
                  onConfirm: async () => {
                    if (confirmOptions.await) {
                      await onClickAction(row.id, row);
                    } else {
                      onClickAction(row.id, row);
                    }
                  },
                });
              } else {
                onClickAction(row.id, row);
              }

              close();
            };

            if (isHidden) {
              return null;
            }

            return (
              <MenuItem key={id} onClick={onClick}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={title} />
              </MenuItem>
            );
          },
        )}
      </Menu>
    </Fragment>
  );
}
