import { createFilterBuilder } from '@jebel/utils';

import {
  useResendSchoolApprovalMutation,
  UserSchoolMembershipFilter,
  UserKeyFilter,
} from 'shared/graphql';
import { useSchoolConfiguration, useToast } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

const SENDING_MEMBER_INVITATION_MESSAGE_KEY = 'SENDING_MEMBER_INVITATION_MESSAGE';

type Options = Parameters<typeof useResendSchoolApprovalMutation>[0];

/**
 * Custom hook to re-send a school member invitation.
 *
 * This hook provides a function to re-send a member invitation for a school and handles
 * success and error notifications.
 *
 * @returns An object containing:
 * - `mutate`: A function to re-send a member invitation.
 * - `loading`: A boolean indicating if the mutation is in progress.
 *
 * @example
 * const { mutate, loading } = useResendMemberInvitation();
 *
 * const handleApprove = async (user) => {
 *   await mutate(user);
 * };
 */
export function useResendMemberInvitation(options?: Options) {
  const { showMessage, showSuccess, showError, dismiss } = useToast();
  const { data: school } = useSchoolConfiguration();

  // Use the same code because `acceptSchoolMember` will only send the emails
  // Because the user is already setup in the database
  // https://8base-dev.atlassian.net/browse/JEB-1558
  const [mutation, { loading }] = useResendSchoolApprovalMutation(options);

  const mutate = async (user: UserKeyFilter) => {
    if (!school) {
      return;
    }

    try {
      showMessage(`Sending invitation to "${user.email ?? user.id}".`, {
        id: SENDING_MEMBER_INVITATION_MESSAGE_KEY,
      });

      const filter = createFilterBuilder<UserSchoolMembershipFilter>();

      if (user.id) {
        filter.or({ user: { id: { equals: user.id } } });
      }

      if (user.email) {
        filter.or({ user: { email: { equals: user.email } } });
      }

      await mutation({
        variables: {
          filter: filter.build(),
          school: { id: school.id },
        },
      });

      showSuccess('Invitation has been sent');
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message, { reportable: false });
      }
    } finally {
      dismiss(SENDING_MEMBER_INVITATION_MESSAGE_KEY);
    }
  };

  return {
    mutate,
    loading,
  };
}
