import { useMemo } from 'react';

import { useAppAuth } from 'providers';
import {
  useRoleAddonCreateMutation,
  useRoleAddonUpdateMutation,
  useSchoolRoleAddonsQuery,
} from 'shared/graphql';
import type { RoleAddonCreateInput, RoleAddonUpdateInput } from 'shared/graphql';

import { useSchoolConfiguration } from './useSchoolConfiguration';

export function useRoleAddons() {
  const { hasSession } = useAppAuth();
  const { data: school } = useSchoolConfiguration();

  const { data, loading: isLoading } = useSchoolRoleAddonsQuery({
    skip: !school?.id || !hasSession,
    variables: {
      school: school?.id as string,
    },
  });

  const [roleAddonCreateMutation, { loading: isCreating }] = useRoleAddonCreateMutation({
    refetchQueries: ['CurrentUser', 'SchoolRoleAddons'],
    awaitRefetchQueries: true,
  });

  const [roleAddonUpdateMutation, { loading: isUpdating }] = useRoleAddonUpdateMutation({
    refetchQueries: ['CurrentUser', 'SchoolRoleAddons'],
    awaitRefetchQueries: true,
  });

  const rolesAddons = useMemo(() => {
    return data?.roleAddonsList.items || [];
  }, [data?.roleAddonsList.items]);

  const createRoleAddon = async (input: RoleAddonCreateInput) => {
    if (!school?.id) {
      return;
    }

    const { data } = await roleAddonCreateMutation({
      variables: {
        input: { ...input, school: { connect: { id: school.id } } },
      },
    });

    return data?.roleAddonCreate.id || null;
  };

  const updateRoleAddon = async (roleId: string, input: RoleAddonUpdateInput) => {
    await roleAddonUpdateMutation({
      variables: {
        roleId,
        input,
      },
    });
  };

  return {
    rolesAddons,
    createRoleAddon,
    updateRoleAddon,
    isLoading,
    isCreating,
    isUpdating,
  };
}
