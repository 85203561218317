import { DateTime } from 'luxon';
import { EMAIL_REGEX } from '@jebel/constants';
/** Checks whether the given `value` is a instance of `DateTime` from `luxon`. */
export function isDateTime(value) {
    return value instanceof DateTime;
}
/** Checks whether the given `value` is a `string`. */
export function isStr(value) {
    return typeof value === 'string';
}
/** Checks whether the given `value` is a `number`. */
export function isNumber(value) {
    return typeof value === 'number';
}
/** Checks whether the given `value` is a integer `number`. */
export function isInteger(value) {
    return Number.isInteger(value);
}
/** Checks whether the given `value` is a `boolean`. */
export function isBool(value) {
    return typeof value === 'boolean';
}
/** Checks whether the given `value` is a `object`. */
export function isObj(value) {
    return typeof value === 'object';
}
/**
 * Checks if a given attribute exists in an object and narrows the type accordingly.
 *
 * @template T - The type of the object to check.
 * @template K - The type of the key to check for in the object.
 * @param value - The object to check.
 * @param attr - The key to check for in the object.
 * @returns A boolean indicating whether the key exists in the object.
 */
export function isProp(value, attr) {
    return Boolean(typeof value === 'object' && value && attr in value);
}
/** Checks whether the given `value` is a `object`. */
// eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
export function isFn(value) {
    return typeof value === 'function';
}
/** Checks whether the given `value` is a `PromiseFulfilledResult<T>`. */
export function isPromiseSuccess(promise) {
    return promise.status === 'fulfilled';
}
/** Checks whether the given `value` is a `PromiseRejectedResult`. */
export function isPromiseError(promise) {
    return promise.status === 'rejected';
}
/** Check the given `value` as valid JSON. */
export function isValidJSON(value) {
    try {
        JSON.parse(value);
        return true;
    }
    catch (err) {
        console.error(err);
    }
    return false;
}
/**
 * Checks if the given string is a valid email address.
 *
 * @param value - The string to be checked.
 * @returns `true` if the string is a valid email address, otherwise `false`.
 */
export function isEmail(value) {
    return EMAIL_REGEX.test(value);
}
