import { SystemRoleName, UserSchoolMembershipStatus } from '@jebel/constants';
/**
 * Determines if a user can be approved based on their status.
 *
 * @param currentStatus - Status of the user school membership.
 * @returns A boolean indicating whether the user can be approved.
 */
export function canSchoolMembershipBeApproved(currentStatus) {
    return (currentStatus === UserSchoolMembershipStatus.Pending ||
        currentStatus === UserSchoolMembershipStatus.Rejected);
}
/**
 * Determines if a user can be activated based on their status.
 *
 * @param currentStatus - Status of the user school membership.
 * @returns A boolean indicating whether the user can be activated.
 */
export function canSchoolMembershipBeActivated(currentStatus) {
    return currentStatus === UserSchoolMembershipStatus.Inactive;
}
/**
 * Determines if a user can be inactivated based on their status.
 *
 * @param currentStatus - Status of the user school membership.
 * @returns A boolean indicating whether the user can be inactivated.
 */
export function canSchoolMembershipBeInactivated(currentStatus) {
    return currentStatus === UserSchoolMembershipStatus.Active;
}
/**
 * Determines if a user can be rejected based on their status.
 *
 * @param currentStatus - Status of the user school membership.
 * @returns A boolean indicating whether the user can be rejected.
 */
export function canSchoolMembershipBeRejected(currentStatus) {
    return (currentStatus === UserSchoolMembershipStatus.InvitationSent ||
        currentStatus === UserSchoolMembershipStatus.Pending);
}
/**
 * Determines if a user can be receive an invitation again based on their status.
 *
 * @param currentStatus - Status of the user school membership.
 * @returns A boolean indicating whether the user can be receive an invitation again.
 */
export function canSendFinalizeRegistrationAgain(currentStatus) {
    return currentStatus === UserSchoolMembershipStatus.InvitationSent;
}
/**
 * Determines if a user can be reset based on their status.
 *
 * @param currentStatus - Status of the user school membership.
 * @returns A boolean indicating whether the user can be reset.
 */
export function canResetSchoolMembershipCredentials(currentStatus) {
    return (currentStatus === UserSchoolMembershipStatus.InvitationSent ||
        currentStatus === UserSchoolMembershipStatus.Active);
}
/**
 * Determines if a user school membership can be promoted to admin.
 *
 * @param currentStatus - The current membership status of the user in the school.
 * @param userSystemRoles - Array of system roles assigned to the user.
 * @returns A boolean indicating whether the membership can be promoted to admin.
 */
export function canSchoolMemberBecomeAdministrator(currentStatus, userSystemRoles) {
    const hasSchooolAdminSystemRole = Boolean(userSystemRoles === null || userSystemRoles === void 0 ? void 0 : userSystemRoles.some(role => role.name === SystemRoleName.CommunityAdmin));
    return currentStatus === UserSchoolMembershipStatus.Active && !hasSchooolAdminSystemRole;
}
