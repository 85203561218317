import {
  ChipProps,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { KeyboardArrowDown as ArrowDownIcon } from '@material-ui/icons';
import { Fragment, useState } from 'react';

import { usePopoverState } from 'shared/hooks';

import { Action, Container, Content } from './ChooseStatusChip.styles';
import { Option } from './ChooseStatusChip.types';

export interface ChooseStatusChipProps {
  label?: string;
  color?: string;
  size?: ChipProps['size'];

  options?: Option[];
}

/**
 * A React component that renders a status chip with a dropdown menu for selecting options.
 * The chip displays a label and allows users to interact with a menu of options.
 *
 * @component
 * @param {ChooseStatusChipProps} props - The properties for the `ChooseStatusChip` component.
 * @param {string} [props.label] - The label displayed on the chip. Defaults to "Unknown" if not provided.
 * @param {string} [props.color] - The background color of the chip.
 * @param {string} [props.size] - The size of the chip.
 * @param {Option[]} [props.options] - An array of options to display in the dropdown menu. Each option should include an `id`, `value`, `label`, and an optional `icon` and `onClick` handler.
 *
 * @example
 * ```tsx
 * const options = [
 *   { id: '1', value: 'active', label: 'Active', onClick: () => console.log('Active selected') },
 *   { id: '2', value: 'inactive', label: 'Inactive', onClick: () => console.log('Inactive selected') },
 * ];
 *
 * <ChooseStatusChip
 *   label="Status"
 *   color="blue"
 *   size="medium"
 *   options={options}
 * />
 * ```
 *
 * @remarks
 * - The component uses a loading state to indicate when an option's `onClick` handler is being executed.
 * - If no options are provided, the dropdown menu will be disabled.
 *
 * @returns A status chip with an optional dropdown menu for selecting options.
 */
export function ChooseStatusChip(props: ChooseStatusChipProps) {
  const [loading, setLoading] = useState(false);

  const { show: open, anchor, open: openMenu, close: closeMenu } = usePopoverState();

  const options = props.options ?? [];
  const isEmpty = options.length === 0;

  const handleClick = async (option: Option) => {
    setLoading(true);
    closeMenu();

    try {
      await option.onClick();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Container>
        <Content size={props.size} background={props.color} label={props.label ?? 'Unknown'} />

        <Action size="small" disabled={isEmpty || loading} onClick={openMenu}>
          {loading ? <CircularProgress size="1rem" /> : <ArrowDownIcon fontSize="small" />}
        </Action>
      </Container>

      <Menu
        open={open}
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={closeMenu}
      >
        {options.map(option => (
          <MenuItem dense key={option.id ?? option.value} onClick={() => handleClick(option)}>
            {Boolean(option.icon) && <ListItemIcon>{option.icon}</ListItemIcon>}
            <ListItemText>{option.label ?? option.value}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Fragment>
  );
}
