/**
 * Represents the status of a community.
 * @see https://github.com/jebelapp/jebel/issues/1728
 */
export var CommunityStatus;
(function (CommunityStatus) {
    CommunityStatus["InvitationSent"] = "InvitationSent";
    CommunityStatus["Active"] = "Active";
    CommunityStatus["Inactive"] = "Inactive";
})(CommunityStatus || (CommunityStatus = {}));
