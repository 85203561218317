export var SystemRoleName;
(function (SystemRoleName) {
    SystemRoleName["CommunityMember"] = "CommunityMember";
    SystemRoleName["CommunityAdmin"] = "CommunityAdmin";
    SystemRoleName["BusinessAdmin"] = "BusinessAdmin";
    SystemRoleName["BusinessManager"] = "BusinessManager";
    /** Administrator of the platform (a.k.a Jebel Admin). */
    SystemRoleName["PlatformAdmin"] = "PlatformAdmin";
})(SystemRoleName || (SystemRoleName = {}));
export const SYSTEM_ROLE_NAMES = [
    SystemRoleName.CommunityMember,
    SystemRoleName.CommunityAdmin,
    SystemRoleName.BusinessAdmin,
    SystemRoleName.BusinessManager,
];
/** @deprecated Use either `SystemRoleName` or `SYSTEM_ROLE_NAMES` instead. */
export const ROLE_NAMES = {
    communityMember: SystemRoleName.CommunityMember,
    communityAdmin: SystemRoleName.CommunityAdmin,
    businessAdmin: SystemRoleName.BusinessAdmin,
    businessManager: SystemRoleName.BusinessManager,
};
