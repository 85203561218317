import { ApolloError } from '@apollo/client';

import { useRejectSchoolMemberMutation, UserFilter } from 'shared/graphql';
import { useSchoolConfiguration, useToast } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

const REJECTING_MEMBERS_MESSAGE_KEY = 'REJECTING_MEMBERS_MESSAGE';

type Options = Parameters<typeof useRejectSchoolMemberMutation>[0];

/**
 * Custom hook to reject members into a school.
 *
 * This hook provides a function to reject members by their user keys and
 * handles the success and error notifications accordingly.
 *
 * @returns An object containing:
 * - `mutate`: A function to reject members.
 * - `loading`: A boolean indicating if the mutation is in progress.
 *
 * @example
 * const { mutate, loading } = useRejectMembers();
 *
 * const handleRejectMembers = async () => {
 *   await mutate({ id: { equals: 'user1' } });
 * };
 */
export function useRejectMembers(options?: Options) {
  const { showMessage, showSuccess, showError, dismiss } = useToast();
  const { data: school } = useSchoolConfiguration();

  const [mutation, { loading }] = useRejectSchoolMemberMutation(options);

  const mutate = async (filter: UserFilter) => {
    if (!school) {
      return;
    }

    try {
      showMessage(`Requesting rejection of selected member(s).`, {
        id: REJECTING_MEMBERS_MESSAGE_KEY,
      });

      await mutation({
        variables: {
          filter,
          school: { id: school.id },
        },
      });

      showSuccess(
        'Rejection request submitted successfully. The process may take some time, and the user(s) will be notified upon completion.',
      );
    } catch (err) {
      recordError(err);

      if (err instanceof ApolloError) {
        const [issue, ...issues] = err.graphQLErrors ?? [];

        let message = issue.message;

        if (issues.length) {
          message += ` (${issues.length} issues more)`;
        }

        showError(message, { reportable: false });
        return;
      }

      if (err instanceof Error) {
        showError(err.message);
      }
    } finally {
      dismiss(REJECTING_MEMBERS_MESSAGE_KEY);
    }
  };

  return {
    mutate,
    loading,
  };
}
