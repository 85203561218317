import { useAppAuth } from 'providers';
import { useUserByIdQuery } from 'shared/graphql';

export function useUserById({ id }: { id?: string | undefined | null }) {
  const { hasSession } = useAppAuth();

  const { data, loading, error } = useUserByIdQuery({
    skip: !hasSession || !id,
    variables: { id },
  });

  return {
    user: data?.user || undefined,
    userId: data?.user?.id,
    loading: loading && !data,
    error,
  };
}
