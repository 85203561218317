import { useMemo } from 'react';

import { useAppAuth } from 'providers';
import { useCurrentUserActiveCommunitiesCountQuery } from 'shared/graphql';

type Options = Parameters<typeof useCurrentUserActiveCommunitiesCountQuery>[0];

/**
 * Custom hook to get the count of active communities for the current user.
 *
 * @param {Options} [options] - Optional parameters to customize the query.
 * @see https://github.com/jebelapp/jebel/issues/1727
 */
export function useCurrentUserActiveCommunitiesCount(options?: Options) {
  const { hasSession } = useAppAuth();

  const { data: response, loading } = useCurrentUserActiveCommunitiesCountQuery({
    ...options,
    skip: !hasSession || options?.skip,
  });

  const count = useMemo(() => {
    return response?.communities.count ?? 0;
  }, [response]);

  return {
    count,
    loading,
  };
}
