import { Fragment, useState } from 'react';
import { Avatar } from '@material-ui/core';
import { PersonAdd } from '@material-ui/icons';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { useCanAddMembers, useCurrentUser, useSchoolConfiguration } from 'shared/hooks';
import { NotificationsPopover } from 'shared/features/notifications/components';
import { InviteMemberModal } from 'shared/components/dialogs';
import { Modal } from 'shared/components/ui';

import {
  Buttons,
  Container,
  IconButton,
  SchoolContainer,
  SchoolName,
} from './HeaderNavigationMobile.styles';
import { HeaderNavigationPopover } from '../HeaderNavigationPopover';
import { SchoolAvatar } from 'shared/components/symbols';

interface Props {
  isAdminRoute?: boolean;
}

const DEFAULT_AVATAR_SIZE = 40;

export function HeaderNavigationMobile(props: Props) {
  const [inviteModalOpen, setInviteModalOpen] = useState(false);

  const { configuration } = useSchoolConfiguration();
  const { user, loading } = useCurrentUser();

  const schoolName = configuration?.name ?? 'School';
  const userAvatar = user?.avatar?.downloadUrl as string;

  const canInviteMembers = useCanAddMembers();

  const onInviteModalOpen = () => {
    setInviteModalOpen(true);
  };

  const onInviteModalClose = () => {
    setInviteModalOpen(false);
  };

  return (
    <Container>
      <SchoolContainer>
        <SchoolAvatar />
        <SchoolName>{schoolName}</SchoolName>
      </SchoolContainer>

      <Buttons>
        {canInviteMembers && (
          <Fragment>
            <IconButton onClick={onInviteModalOpen}>
              <PersonAdd color="inherit" />
            </IconButton>

            <Modal
              isVisibleDivider
              dialogContentWidth={280}
              titleProps={{ title: 'Invite friends' }}
              dialogProps={{
                open: inviteModalOpen,
                onClose: onInviteModalClose,
                fullWidth: true,
                maxWidth: 'xs',
              }}
            >
              <InviteMemberModal onClose={onInviteModalClose} />
            </Modal>
          </Fragment>
        )}

        <NotificationsPopover />

        <HeaderNavigationPopover
          user={user}
          userLoading={loading}
          isAdminRoute={props.isAdminRoute}
          target={
            <Avatar
              variant="circular"
              src={userAvatar}
              srcSet={processFilestackUrlSrcSet(userAvatar, {
                compress: true,
                resize: { width: DEFAULT_AVATAR_SIZE, height: DEFAULT_AVATAR_SIZE, fit: 'crop' },
              })}
            />
          }
        />
      </Buttons>
    </Container>
  );
}
