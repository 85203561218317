import styled from '@emotion/styled';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { ChangeEvent, useMemo, useState } from 'react';

import { SelectOption, USER_STATUSES, UserStatuses } from '@jebel/constants';

import { SpreadsheetStat, SpreadsheetStats } from 'shared/components/ui';
import { SELECT_OPTION_ALL } from 'shared/constants';

import { useMembersStats } from '../hooks';

const FILTER_STATUS_OPTIONS: SelectOption[] = [
  {
    label: 'All',
    value: SELECT_OPTION_ALL,
  },
  {
    label: 'Active',
    value: USER_STATUSES.active,
  },
  {
    label: 'Inactive',
    value: USER_STATUSES.inactive,
  },
  {
    label: 'Invitation Sent',
    value: USER_STATUSES.invitationSent,
  },
  {
    label: 'Rejected',
    value: USER_STATUSES.rejected,
  },
];

export function MembersStats() {
  const [filterStatus, setFilterStatus] = useState(SELECT_OPTION_ALL);

  const { membersStats, loading } = useMembersStats(filterStatus as UserStatuses);

  const membersStatsCards = useMemo(() => {
    const stats: SpreadsheetStat[] = [
      {
        label: 'Total Members',
        value: membersStats?.totalCount,
        color: 'secondary',
      },
      {
        label: 'Students',
        value: membersStats?.student,
      },
      {
        label: 'Parents',
        value: membersStats?.parent,
      },
      {
        label: 'Alumni',
        value: membersStats?.alumni,
      },
      {
        label: 'Community Staff',
        value: membersStats?.staff,
      },
      {
        label: 'Other',
        value: membersStats?.other,
      },
    ];

    return stats;
  }, [membersStats]);

  const handleFilterStatusChange = (event: ChangeEvent<{ value: unknown }>) => {
    setFilterStatus(event.target.value as string);
  };

  return (
    <Container>
      <FormControl size="small">
        <Select
          variant="outlined"
          value={filterStatus}
          disabled={loading}
          onChange={handleFilterStatusChange}
        >
          {FILTER_STATUS_OPTIONS.map(({ label, value }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <SpreadsheetStats loading={loading} data={membersStatsCards} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
`;
