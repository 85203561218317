import { flatten, isNil, uniqBy } from 'ramda';
import { useMemo } from 'react';

import { ROUTES, USER_SIDEBAR_NAVIGATION_ITEMS } from 'shared/constants';
import type {
  RoleAddonFragment,
  RoleAddonInfoFragment,
  SchoolConfigurationLayout,
} from 'shared/graphql';
import {
  sortLabels,
  useCurrentUserRoleAddons,
  useSchoolConfiguration,
  useRoleAddons,
} from 'shared/hooks';
import type { SidebarNavigationItemList } from 'shared/types';

import { matchRoute } from '../components/ui';
import { useCurrentUserRoles } from './useCurrentUserRoles';

export const getAccessibleLayouts = (
  userRoleAddons: RoleAddonFragment[],
  rolesAddons: RoleAddonInfoFragment[],
): SchoolConfigurationLayout[] => {
  const userRolesAddonsIds = userRoleAddons.map(roleAddon => roleAddon.id);
  const filteredRoles = rolesAddons.filter(roleAddon => userRolesAddonsIds.includes(roleAddon?.id));
  const allAccessibleLayouts = flatten(
    filteredRoles.map(role => role.accessibleLayouts?.items || []),
  );

  return uniqBy(layout => layout.id, allAccessibleLayouts)
    .concat({ route: ROUTES.user.search.index })
    .concat({ route: ROUTES.user.settings.index });
};

const mergeNavigationItems = (
  configurationItems: SchoolConfigurationLayout[],
  accessibleLayoutsIds: string[],
  staticItems: SidebarNavigationItemList,
): SidebarNavigationItemList => {
  return sortLabels(configurationItems)
    .filter(({ isActive = false, route = '', isAlwaysShown = false, id = '' }) => {
      let isValid = isActive;
      isValid &&= staticItems.some(({ route: staticRoute }) => {
        return matchRoute(staticRoute, route as string, true);
      });
      isValid &&= isAlwaysShown || accessibleLayoutsIds.includes(id || '');
      return isValid;
    })
    .map(item => ({
      name: item.name as string,
      route: item.route as string,
      icon: item.icon as string,
    }));
};

interface SidebarMenuItemsHookResult {
  sidebarMenuItems: SidebarNavigationItemList;
  isLoading: boolean;
}

export function useSidebarMenuItems(): SidebarMenuItemsHookResult {
  const { data: userRoleAddons, loading: userRolesLoading } = useCurrentUserRoleAddons();
  const { isPlatformAdmin, loading: userSystemRolesLoading } = useCurrentUserRoles();
  const { rolesAddons, isLoading: rolesLoading } = useRoleAddons();
  const { data: school, loading: schoolLoading } = useSchoolConfiguration();

  const isLoading = userRolesLoading || rolesLoading || schoolLoading || userSystemRolesLoading;

  const sidebarMenuItems = useMemo<SidebarNavigationItemList>(() => {
    if (isPlatformAdmin) {
      return USER_SIDEBAR_NAVIGATION_ITEMS;
    }

    if (isNil(userRoleAddons) || isNil(school) || rolesAddons.length === 0) {
      return [];
    }

    return mergeNavigationItems(
      school.layout?.items || [],
      getAccessibleLayouts(userRoleAddons, rolesAddons).map(layout => layout.id || ''),
      USER_SIDEBAR_NAVIGATION_ITEMS,
    );
  }, [school, isPlatformAdmin, userRoleAddons, rolesAddons]);

  return {
    sidebarMenuItems,
    isLoading,
  };
}
