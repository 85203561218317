import { DateTime } from 'luxon';
import { DATE_SQL_FORMAT, DATE_TABLE_FORMAT, DATE_USA_FORMAT, REGEX_SQL_FORMAT, REGEX_TABLE_FORMAT, REGEX_USA_FORMAT, } from '@jebel/constants';
import { isFn, isProp, isStr } from '../types';
export const SECONDS_TO_MILLISECONDS = 1000;
export const MILLISECONDS_TO_SECONDS = 0.001;
const UNIX_THRESHOLD = 10000000000;
/**
 * A constant string representing the reason for no matching date-time format.
 * This can be used to identify cases where a date-time string does not match any expected format.
 */
export const NO_DATETIME_FORMAT_MATCH_REASON = 'NO_DATETIME_FORMAT_MATCH';
/**
 * Parses a given value into a DateTime object based on various formats and types.
 *
 * @internal DO NOT USE DIRECTLY. Use `parseDate` instead.
 *
 * @param value - The value to be parsed. It can be of type {@linkcode ParseDateValue} which includes `DateTime`, `number`, `Date`, or `string`.
 * @param options - Optional {@linkcode DateTimeOptions} to customize the parsing behavior.
 * @returns A `DateTime` object parsed from the input value.
 */
function parse(value, options) {
    if (value instanceof DateTime) {
        return value;
    }
    if (isProp(value, 'toISO') && isFn(value.toISO)) {
        // Allow to parse other versions of luxon by formatting into ISO.
        return parseDate(value.toISO(), options);
    }
    if (typeof value === 'number') {
        if (value < UNIX_THRESHOLD) {
            // Value is in UNIX format, convert to milliseconds
            value = value * SECONDS_TO_MILLISECONDS;
        }
        // Always use milliseconds
        // https://8base-dev.atlassian.net/browse/JEB-1594?focusedCommentId=46027
        return DateTime.fromMillis(value, options);
    }
    if (value instanceof Date) {
        return DateTime.fromJSDate(value, options);
    }
    if (isStr(value) && REGEX_USA_FORMAT.test(value)) {
        return DateTime.fromFormat(value, DATE_USA_FORMAT, options);
    }
    if (isStr(value) && REGEX_SQL_FORMAT.test(value)) {
        return DateTime.fromFormat(value, DATE_SQL_FORMAT, options);
    }
    if (isStr(value) && REGEX_TABLE_FORMAT.test(value)) {
        return DateTime.fromFormat(value, DATE_TABLE_FORMAT, options);
    }
    if (isStr(value)) {
        return DateTime.fromISO(value, options);
    }
    return DateTime.invalid(NO_DATETIME_FORMAT_MATCH_REASON);
}
/**
 * Parse date from `string`, `number` or `Date` to luxon's {@link DateTime}.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date).
 * @param options Optional {@linkcode ParseDateOptions} to customize the parsing behavior.
 *
 * @throws If the date cannot be parsed or is not one of the supported formats.
 */
export function parseDate(value, options) {
    var _a;
    const date = parse(value, options);
    if (!date.isValid && (options === null || options === void 0 ? void 0 : options.throwsOnInvalid)) {
        throw new SyntaxError((_a = date.invalidReason) !== null && _a !== void 0 ? _a : undefined);
    }
    return date;
}
export const PLATFORM_LOCAL_TIMEZONE = 'America/New_York';
/** Gives the current date-time as {@link DateTime} instance from `luxon`. */
export function currentDateTime(timezone) {
    return DateTime.local({ zone: timezone });
}
