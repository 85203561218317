import { MouseEvent } from 'react';

import { useModalState } from './useModalState';

/**
 * Creates a stateful set of features to manage a popover.
 */
export function usePopoverState<E extends Element>() {
  const { show, params: anchor, open, close } = useModalState<E>();

  const anchorClick = (event: MouseEvent<E>) => {
    open(event.currentTarget ?? event.target);
  };

  return {
    show,
    anchor,

    open: anchorClick,
    close,
  };
}
