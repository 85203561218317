import { Box, Button, Typography } from '@material-ui/core';
import { init as setupSentry, replayIntegration } from '@sentry/browser';
import posthog from 'posthog-js';
import { Component, Fragment, PropsWithChildren } from 'react';

import { recordException } from 'shared/utils/record';

const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const SENTRY_TRACE_SAMPLE_RATE = Number(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE);
const SENTRY_TRACE_ERROR_SAMPLE_RATE = Number(process.env.REACT_APP_SENTRY_TRACE_ERROR_SAMPLE_RATE);

const POSTHOG_TOKEN = process.env.REACT_APP_POSTHOG_TOKEN;

const IS_DEVELOPMENT = process.env.NODE_ENV === 'development';
const ENVIRONMENT = process.env.REACT_APP_WORKSPACE_ENV;

if (SENTRY_DSN) {
  /** Enable sentry for analytics and metrics. */

  setupSentry({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    enabled: !IS_DEVELOPMENT,
    release: process.env.REACT_APP_VERSION,

    tracesSampleRate: 1,
    tracePropagationTargets: ['https://api.8base.com'],

    replaysSessionSampleRate: SENTRY_TRACE_SAMPLE_RATE,
    replaysOnErrorSampleRate: SENTRY_TRACE_ERROR_SAMPLE_RATE,

    ignoreErrors: ['Invalid message type!', 'Failed to fetch'],

    integrations: [replayIntegration()],

    beforeBreadcrumb(breadcrumb) {
      if (breadcrumb.category === 'console') {
        // Remove the built-in breadcrumbs.
        return null;
      }

      return breadcrumb;
    },
  });
}

if (POSTHOG_TOKEN) {
  posthog.init(POSTHOG_TOKEN, {
    api_host: 'https://us.i.posthog.com',
    // https://posthog.com/docs/libraries/js
    person_profiles: 'always',
  });
}

export class LoggerProvider extends Component<PropsWithChildren<unknown>> {
  state = {
    id: null,
    error: null,
  };

  componentDidCatch(error: Error) {
    const id = recordException(error);
    this.setState({ id, error });
  }

  render() {
    const goHome = () => {
      window.open('/', '_self');
    };

    if (this.state.error) {
      return (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          padding="2rem"
          gridGap="1rem"
          minHeight="100vh"
        >
          <Typography align="center">
            Something went wrong when handling your transaction, please try again later.
          </Typography>

          {this.state.id && (
            <Fragment>
              <Typography align="center" color="textSecondary">
                Please provide the next ID to report the issue:
              </Typography>

              <Typography component="code" align="center" color="secondary">
                {this.state.id}
              </Typography>
            </Fragment>
          )}

          <Button variant="outlined" onClick={goHome}>
            Go Home
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}
