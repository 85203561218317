export class FilterBuilder {
    constructor(initial) {
        this.unions = [];
        this.intersections = [];
        if (Array.isArray(initial.AND)) {
            this.unions = initial.AND;
            // Remove the initial AND clause to avoid duplication.
            initial = { ...initial, AND: undefined };
        }
        if (Array.isArray(initial.OR)) {
            this.intersections = initial.OR;
            // Remove the initial OR clause to avoid duplication.
            initial = { ...initial, OR: undefined };
        }
        this.initial = initial;
    }
    and(...rules) {
        for (const rule of rules) {
            if (rule instanceof FilterBuilder) {
                this.and(rule.build());
                continue;
            }
            const keys = Object.keys(rule);
            if (keys.length === 0) {
                continue;
            }
            this.unions.push({ ...rule });
        }
        return this;
    }
    or(...rules) {
        for (const rule of rules) {
            if (rule instanceof FilterBuilder) {
                this.or(rule.build());
                continue;
            }
            const keys = Object.keys(rule);
            if (keys.length === 0) {
                continue;
            }
            this.intersections.push({ ...rule });
        }
        return this;
    }
    build() {
        const filter = { ...this.initial };
        const intersectionsCount = Number(this.intersections.length);
        const unionsCount = Number(this.unions.length);
        if (intersectionsCount === 0 && unionsCount === 1) {
            // Returns the only union if there are no intersections.
            return { ...filter, ...this.unions[0] };
        }
        const rules = Number(Object.keys(filter).length);
        if (intersectionsCount === 1 && unionsCount === 0 && rules === 0) {
            // Returns the only intersection if there are no unions.
            return { ...this.intersections[0] };
        }
        if (intersectionsCount > 0) {
            filter.OR = this.intersections;
        }
        if (unionsCount > 0) {
            filter.AND = this.unions;
        }
        return filter;
    }
}
/**
 * Creates a new instance of `FilterBuilder` with optional initial filter values.
 *
 * @template R - Type extending `GenericFilter` for type-safe filter operations.
 * @param {R | undefined | null} initial - Initial filter values to populate the builder.
 * @returns {FilterBuilder<R>} A new `FilterBuilder` instance with the provided initial values.
 */
export function createFilterBuilder(initial) {
    return new FilterBuilder(initial !== null && initial !== void 0 ? initial : {});
}
