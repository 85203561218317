import { useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';

import { HomeFeedItemURLToken, parseHomeFeedItemText } from '@jebel/utils';

import { LinkPreview } from 'shared/components/ui';

import { PostModalCreateFormValues } from './PostModalCreate.types';

/**
 * Component that previews links extracted from the text input in the post creation modal.
 *
 * This component uses the `useFormikContext` hook to access the form values and extracts URLs from the text input using the `parseHomeFeedItemText` function. It then displays a preview of each extracted link using the {@linkcode LinkPreview} component.
 *
 * @remarks
 * The component debounces the link extraction process to avoid excessive re-renders while the user is typing.
 *
 * @hook
 * - `useState`: Manages the state of the extracted links.
 * - `useFormikContext`: Accesses the form values from the parent form.
 * - `useCallback`: Memoizes the `refreshLinks` function to optimize performance.
 * - `useEffect`: Triggers the `refreshLinks` function when the text input value changes.
 *
 * @function
 * - `refreshLinks`: Extracts URLs from the text input and updates the `links` state.
 */
export function PostModalCreateLinksPreview() {
  const [text, setText] = useState<string>('');

  const { values } = useFormikContext<PostModalCreateFormValues>();

  const links = useMemo(() => {
    const tokens = parseHomeFeedItemText(values.text);

    const urls = tokens.filter(
      (section): section is HomeFeedItemURLToken => section.token === 'url',
    );

    // Sync the links with the form values.
    // https://github.com/jebelapp/jebel/issues/1744
    return urls.map(section => section.url);
  }, [text]);

  const setTextDebounced = useCallback(
    debounce((value: string) => setText(value), 300, { leading: false, trailing: true }),
    [setText],
  );

  useEffect(() => {
    // Avoids re-rendering the component when the text input changes.
    setTextDebounced(values.text);

    return () => {
      // Cancels the debounced function when the component unmounts.
      setTextDebounced.cancel();
    };
  }, [values.text]);

  return (
    <Fragment>
      {links.map(link => (
        <LinkPreview key={link} url={link} />
      ))}
    </Fragment>
  );
}
