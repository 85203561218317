import { uniqBy } from 'ramda';
import { useState } from 'react';

import { useAppAuth } from 'providers';
import {
  CurrentUserNotificationsQuery,
  UserNotificationReadableFragment,
  useCurrentUserNotificationsQuery,
} from 'shared/graphql';

type Options = Parameters<typeof useCurrentUserNotificationsQuery>[0];

export function useCurrentUserNotifications(options?: Options) {
  const { hasSession } = useAppAuth();

  const {
    data: response,
    loading,
    refetch,
    fetchMore,
  } = useCurrentUserNotificationsQuery({
    ...options,

    skip: !hasSession || options?.skip,
  });

  const data: UserNotificationReadableFragment[] = response?.notifications.items ?? [];
  const count = response?.notifications.count ?? 0;

  return {
    data,
    count,
    loading,
    fetchMore,
    refetch,
  };
}

export function useCurrentUserNotificationsPaginated(options?: Options) {
  const [fetching, setFetching] = useState(false);

  const { data, count, loading, refetch, fetchMore } = useCurrentUserNotifications(options);

  const onFetchMore = async () => {
    setFetching(true);

    try {
      await fetchMore({
        variables: {
          first: options?.variables?.first,
          skip: data.length,
        },

        updateQuery(prev, { fetchMoreResult: curr }) {
          const oldest = prev?.notifications.items ?? [];
          const newest = curr?.notifications?.items ?? [];

          const count = curr?.notifications.count ?? 0;
          /** Merge between `oldest` items and `newest` reduced by ID. */
          const items = uniqBy(notification => notification.id, [...oldest, ...newest]);

          const data: CurrentUserNotificationsQuery = {
            ...curr,

            notifications: {
              ...prev.notifications,

              count,
              items,
            },
          };

          return data;
        },
      });
    } finally {
      setFetching(false);
    }
  };

  return {
    data,
    count,
    hasMore: data.length < count,

    loading,
    fetching,

    refetch,
    fetchMore: onFetchMore,
  };
}
