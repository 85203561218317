import { gql } from '@apollo/client';

export const MEMBERS_STATS_QUERY = gql`
  query MemberStats($filter: UserFilter) {
    affiliations: usersList(
      filter: $filter
      groupBy: {
        query: { id: { as: "count", fn: { aggregate: COUNT } }, affiliation: { as: "affiliation" } }
      }
    ) {
      totalCount: count
      groups {
        affiliation: String
        count: Int
      }
    }
  }
`;

export const SCHOOL_MEMBER_FRAGMENT = gql`
  fragment SchoolMember on SchoolMember {
    id
    firstName
    lastName
    email
    affiliation
    status
    hobbies
    activityClubs
    birthDate
    gender
    graduatingYear
    phoneNumber
    professionalDesignations
    registrationCompletedAt
    createdAt
    distance
    groupPostsCount
    homePostsCount

    avatar {
      id
      downloadUrl
    }

    roleAddons {
      id
      name
    }

    systemRoles {
      id
      name
    }
  }
`;

export const SCHOOL_MEMBERS_REPORT_QUERY = gql`
  query SchoolMembersReport(
    $first: Int
    $skip: Int
    $startPointZip: String
    $radius: String
    $school: SchoolConfigurationKeyFilter!
    $filter: UserSchoolMembershipFilter
    $sort: [UserSchoolMembershipSort!]
  ) {
    members: schoolMembersReport(
      first: $first
      skip: $skip
      school: $school
      filter: $filter
      sort: $sort
      startPointZip: $startPointZip
      radius: $radius
    ) {
      count
      items {
        ...SchoolMember
      }
    }
  }

  ${SCHOOL_MEMBER_FRAGMENT}
`;

export const SCHOOL_MEMBERS_REPORT_COUNT_QUERY = gql`
  query SchoolMembersReportCount(
    $school: SchoolConfigurationKeyFilter!
    $filter: UserSchoolMembershipFilter
    $startPointZip: String
    $radius: String
  ) {
    report: schoolMembersReport(
      school: $school
      filter: $filter
      startPointZip: $startPointZip
      radius: $radius
    ) {
      count
    }
  }
`;

/** Only users with the role of "SchoolAdmin" can invoke this mutation. */
export const ACCEPT_SCHOOL_MEMBER_MUTATION = gql`
  mutation AcceptSchoolMember(
    $filter: UserSchoolMembershipFilter!
    $school: SchoolConfigurationKeyFilter!
  ) {
    acceptSchoolMembers(filter: $filter, school: $school) {
      success
    }
  }
`;

/** Only users with the role of "SchoolAdmin" can invoke this mutation. */
export const ACTIVATE_SCHOOL_MEMBER_MUTATION = gql`
  mutation ActivateSchoolMembers(
    $filter: UserSchoolMembershipFilter!
    $school: SchoolConfigurationKeyFilter!
  ) {
    activateSchoolMembers(filter: $filter, school: $school) {
      success
    }
  }
`;

/** Only users with the role of "SchoolAdmin" can invoke this mutation. */
export const INACTIVATE_SCHOOL_MEMBER_MUTATION = gql`
  mutation InactivateSchoolMembers(
    $filter: UserSchoolMembershipFilter!
    $school: SchoolConfigurationKeyFilter!
  ) {
    inactivateSchoolMembers(filter: $filter, school: $school) {
      success
    }
  }
`;

/** Only users with the role of "SchoolAdmin" can invoke this mutation. */
export const REJECT_SCHOOL_MEMBER_MUTATION = gql`
  mutation RejectSchoolMember(
    $filter: UserSchoolMembershipFilter!
    $school: SchoolConfigurationKeyFilter!
  ) {
    rejectSchoolMembers(filter: $filter, school: $school) {
      success
    }
  }
`;

/** Only users with the role of "SchoolAdmin" can invoke this mutation. */
export const RESEND_SCHOOL_APPROVAL_MUTATION = gql`
  mutation ResendSchoolApproval(
    $filter: UserSchoolMembershipFilter!
    $school: SchoolConfigurationKeyFilter!
  ) {
    resendSchoolApprovals(filter: $filter, school: $school) {
      success
    }
  }
`;
