import { useMemo } from 'react';

import { useAppAuth } from 'providers';
import { RoleAddonFragment, useCurrentUserRoleAddonsQuery } from 'shared/graphql';

import { useSchoolConfiguration } from './useSchoolConfiguration';

/**
 * Custom hook that retrieves the current user's role addons.
 * @returns An object containing the current user's role addons.
 */
export function useCurrentUserRoleAddons() {
  const { hasSession } = useAppAuth();
  const { data: school, loading: schoolLoading } = useSchoolConfiguration();

  const { data: response, loading: rolesLoading } = useCurrentUserRoleAddonsQuery({
    skip: !school?.id || !hasSession,
    variables: {
      school: school?.id as string,
    },
  });

  const roles: RoleAddonFragment[] = useMemo(() => response?.rolesAddons?.items ?? [], [response]);

  return {
    data: roles,
    loading: rolesLoading || schoolLoading,
  };
}
